@import "../../variables";
@import "../../mixins";

.counterparties-tab {
  display: flex;
  flex-direction: column;
  height: 650px;

  .edit-container {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .edit-button {
      padding: 8px;

      .edit-icon {
        font-size: 12px;
        color: $tdx-black-alpha-87;
      }
    }
  }

  .pagination {
    align-self: center;
  }

  .row {
    display: flex;
    border-bottom: 1px solid $tdx-gray;
    align-items: center;
    padding: 10px 16px;
    min-height: 48px;
  }

  .header {
    height: 30px;
    display: flex;
    align-items: center;
    padding: 10px 16px;
    border-bottom: 1px solid $tdx-gray;
  }

  .column {
    padding-right: 10px;
    flex-shrink: 0;
    //word-break: break-all;
    word-break: break-word;

    &.mt {
      margin-top: 10px;
    }

    &.c-1 {
      width: 35%;
      margin: auto 0;
      font-weight: 700 !important;
      color: $tdx-black-alpha-87;
    }
    &.c-2 {
      width: 35%;
    }

    &.c-3 {
      width: 25%;
    }

    &.c-4 {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      button {
        border: none;
        background: none;
        text-decoration: underline;
        font-weight: 700 !important;
        color: $tdx-black-alpha-87;
        font-family: "Roboto", sans-serif;

        &:first-child {
          margin-bottom: 5px;
        }

        &:hover {
          cursor: pointer;
        }

        &:active {
          color: $tdx-red;
        }
      }
    }

    @include min {
      &.c-3 {
        width: 230px;
      }
      &.c-4 {
        width: 140px;
      }
      &.c-5 {
        width: 70px;
      }
      &.c-6 {
        width: 185px;
      }
    }

    &.body-1 {
      font-weight: 400 !important;
      color: $tdx-black-alpha-87;
    }

    a {
      text-decoration: underline;
      font-weight: 700 !important;
      color: $tdx-black-alpha-87;
    }

    img {
      width: 68px;
      height: 68px;
      object-fit: cover;
    }

    .tdx-button {
      width: 100%;
      font-size: 13px !important;
      padding: 6px 0;
    }
  }

  //.MuiDataGrid-root {
  //  font-size: 14px;
  //  border: none;
  //
  //  @include min {
  //    font-size: 12px;
  //    max-width: 1000px;
  //  }
  //
  //
  //
  //  .MuiDataGrid-columnHeaders {
  //    .MuiDataGrid-columnHeader {
  //      .MuiDataGrid-iconSeparator {
  //        display: none;
  //      }
  //
  //      .MuiDataGrid-columnHeaderTitleContainer {
  //        padding: 0;
  //
  //        .MuiDataGrid-columnHeaderTitle {
  //          font-size: 10px;
  //          font-weight: 700;
  //
  //          @include min {
  //            font-size: 9px;
  //          }
  //        }
  //      }
  //    }
  //  }
  //
  //  .MuiDataGrid-virtualScroller {
  //    .MuiDataGrid-row {
  //      .MuiDataGrid-cell {
  //        .counterparty-name {
  //          color: $tdx-black-alpha-87;
  //          font-weight: 700;
  //        }
  //
  //        .edit-container {
  //          width: 100%;
  //          display: flex;
  //          justify-content: space-between;
  //
  //          .edit-button {
  //            padding: 8px;
  //
  //            .edit-icon {
  //              font-size: 12px;
  //              color: $tdx-black-alpha-87;
  //            }
  //          }
  //        }
  //      }
  //    }
  //
  //    &::-webkit-scrollbar {
  //      height: 9px;
  //      width: 9px;
  //    }
  //
  //    &::-webkit-scrollbar-thumb {
  //      border: 3px solid #fff;
  //      background-clip: padding-box;
  //      background: $tdx-black-alpha-30;
  //      border-radius: 4px;
  //    }
  //  }
  //
  //  .MuiDataGrid-columnHeader,
  //  .MuiDataGrid-cell {
  //    padding: 0 17px;
  //
  //    &:focus,
  //    &:focus-within {
  //      outline: none;
  //    }
  //  }
  //
  //  .MuiDataGrid-footerContainer {
  //    min-height: 62px;
  //    justify-content: center;
  //  }
  //}
}
