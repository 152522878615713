@import "../../../variables";

.tdx-order-nested-window {
  .MuiBackdrop-root {
    background-color: rgba(255, 255, 255, 0.8);
  }

  .tdx-order-nested-window-modal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 1170px;
    max-height: 80%;
    border-radius: 10px;
    overflow: hidden auto;
    outline: none !important;

    @include min {
      max-width: 1000px;
    }

    .close-button {
      position: absolute;
      right: 10px;
      top: 10px;
      padding: 4px;
      background-color: $tdx-black-alpha-4;

      .MuiSvgIcon-root {
        font-size: 28px;
        color: rgba(0, 0, 0, 0.54);
      }

      &:hover {
        background-color: $tdx-black-alpha-12;

        .MuiSvgIcon-root {
          color: $tdx-black-alpha-75;
        }
      }
    }

    .modal-title {
      text-align: center;
      padding: 20px 80px;
    }

    .modal-body {
      .label {
        margin: 10px 0;
      }

      .create-new-order-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 150px;

        h2 {
          font-weight: 500 !important;
          margin-bottom: 10px;
        }

        .body-2.gray {
          font-weight: 400 !important;
          color: #808080;

          span {
            font-weight: 700 !important;
          }
        }
      }

      .height-fix {
        flex: 0 0 auto;
        visibility: hidden;
        height: 64px;
      }
    }

    .modal-actions {
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0 50px;

      &.shadow {
        box-shadow: 0px -4px 9px rgba(0, 0, 0, 0.1);
      }

      .tdx-button {
        min-width: 100px;

        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  }
}
