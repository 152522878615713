@import "../../variables";
@import "../../mixins";

.tdx-supply-page {
  padding: 24px 0 60px 0;

  h1 {
    margin-bottom: 20px;
  }

  .info {
    display: flex;
    justify-content: space-between;

    .icon {
      font-size: 1.2rem;
    }
    .clear-icon {
      color: $tdx-red;
    }

    .edit-button {
      color: $tdx-black-alpha-87;
      padding: 3px;
    }

    .actions-container {
      display: flex;

      .action {
        display: flex;
        align-items: center;

        &:not(:last-child) {
          margin-right: 25px;
        }

        .subtitle-1 {
          margin-left: 5px;
        }
      }
    }
  }

  .tdx-table-container {
    .cell:last-child {
      justify-content: flex-start;
    }

    .clear-icon {
      padding: 1px;
      color: $tdx-red;

      &:hover {
        background: none;
      }
    }

    .edit-button {
      padding: 2px;

      color: $tdx-black-alpha-87;

      &:hover {
        background: none;
      }
    }
  }
}

.tdx-supply-edit {
  .supply-edit {
    margin: 20px;
    .MuiFormControl-root {
      min-width: 250px;
      margin-right: 20px;
    }
  }

  .select-supplier .MuiOutlinedInput-root {
    height: 100%;
  }
}
