@import "../../variables";
@import "../../mixins";

.tdx-search-page {
  padding-top: 25px;
  padding-bottom: 60px;

  @include small {
    width:95% !important;
  }

  & > h1 {
    span {
      &:not(:last-child){
        margin-right: 0.3em;
      }
    }
  }

  .categories-container {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0;
    gap: 10px;

    .category-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      margin-right: 20px;

      @include small {
        margin-bottom: 0;
        margin-right: 0;
      }

      .search-category-link {
        color: $tdx-black;
        margin-left: 5px;
        text-decoration: none;
        vertical-align: middle;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    @include min {
      padding-bottom: 20px;
    }
  }

  .products-container {
    .tdx-table-container {
      .row {

        @include small {
          background-color: $tdx-light-gray;
          border-radius: $tdx-btn-border-radius;
        }
        .content {
          .checkbox-container {
            min-width: 450px;
            gap: 10px;
            display: flex;
            justify-content: space-between;

            @include little {
              min-width: 100%;
              flex-wrap: wrap;
              gap: 0;
            }

            & label {
              margin-bottom: 5px
            }
          }
        }

        .titles-wrapper {
          display: flex;
          gap: 10px;
          align-items: center;

          .titles {
            display: flex;
            flex-direction: column;


            @include small {
              text-align: center;
              height: 100px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              width: 100%;
            }

            @include little {
              height: 150px;
            }

            .subtitle-1 {
              color: $tdx-black-alpha-60;
            }
          }

          .icon-popper {
            svg {
              font-size: 1.57rem;
            }

            @include small {
              display:none;
            }
          }
        }

        .tdx-button.large.bg-blue,
        .tdx-button.large.bg-gray,
        .tdx-button.large.bg-green {
          height: 55px;
          max-height: 55px;
          min-width: 180px;
          flex: 1;

          @include small {
            flex-direction: column;
            width: 100%;
          }

          &:not(:last-child) {
            margin-right: 5px;

            @include small {
              margin-right: 0;
              margin-bottom: 5px;
            }
          }
        }

        .tdx-button.counter {
          position: relative;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          padding: 6px;
          transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

          @include small {
            margin-bottom: 0 !important;
            padding: 5px;
            min-width: 100% !important;
          }

          .counter {
            margin: 0 10px;

            @include small {
              margin: 0
            }
          }
        }
      }
      .table-content {
        .cell {
          &.body-1 {
            &:first-child {

              @include small {
                display: none;
              }
            }

            &.col-unknown {

              @include little  {
                padding: 0;
              }
            }
          }
        }
        @include small {
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
        }
        .row-wrapper {

          @include small {
            width: 49%;
            border-radius: 10px;
            background-color: $tdx-light-gray;
            margin-bottom: 10px;
          }

          & .col-opisanie {
            @include small{
            display: none;
            }
          }

          & .col-kod-postavshchika {
            flex-direction: column;

            @include small{
              width: 100%;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
