.tdx-news-list-page {
  padding: 24px 0 60px 0;
   &.wrapper {

     @include small {
       width: 90%;
     }
   }
  h1 {
    margin-bottom: 24px;
    @include small {
      font-size: 22px;
    }
    @include little {
      text-align: center;
    }
  }

  .news-container {
    display: flex;
    justify-content: space-between;
    align-content: flex-start;
    flex-flow: row wrap;

    .tdx-news-card {
      margin-bottom: 24px;
    }
  }

  .news-pagination {
    display: flex;
    justify-content: center;
    padding-top: 36px;
  }
}
