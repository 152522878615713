@import "../../variables";
@import "../../mixins";

.users-settings-profile-editing {
  position: relative;

  @include min {
    .main-container {
      height: 433px;
      overflow: scroll;
    }
  }

  .inputs-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 30px;

    .container {
      &:not(:last-child) {
        border-bottom: 1px solid $tdx-gray;
      }

      &:first-child {
        padding-top: 12px;
      }

      &:not(:first-child) {
        padding-top: 32px;
      }
    }

    .row {
      display: flex;
    }

    .column {
      display: flex;
      flex-direction: column;
    }

    .input-wrapper {
      padding-bottom: 32px;

      & + .input-wrapper {
        margin-left: 50px;
      }

      & > .mb:not(:last-child) {
        margin-bottom: 12px;
      }

      .input-narrow {
        width: 218px;
      }

      .input-wide {
        width: 400px;
      }
    }
  }

  .submit-container {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $tdx-black-alpha-2;
  }
}
