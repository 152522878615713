@import "../variables";
@import "../mixins";

.tdx-table-mobile-container {

  @include little {
    width: 100%;
  }

  .table-mobile {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include little {
      flex-direction: column;
      width: 100%;
    }

    .mobile-card  {
      background-color: $tdx-gray-light;
      width: 48%;
      margin-bottom: 10px;
      border-radius: $tdx-btn-border-radius;

      @include little {
        width: 100%;
      }

      .row {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        @include small {
          gap: 10px;
        }

        .mobile-card-first-row {
          display: flex;
          justify-content: space-between;
          max-width: 100%;
          position: relative;

          .mobile-card-checkbox-column {
            width: 10% !important;
            max-width: 10% !important;
            min-width: 10% !important;
          }

          .mobile-card-wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            gap: 5px;

            .col-artikul-proizvoditelya {
              & > * {
                color: #6A6A6A;
                font-size: 12px;
              }
            }

            .col-nazvanie, .col-naimenovanie {
              font-weight: bold !important;
            }

            .body-1 {
              width: 100% !important;
              max-width: 100% !important;
              min-width: 100% !important;
            }

            &.green {
              background-color: $tdx-green-alpha-10;
            }

            &.red {
              background-color: $tdx-red-alpha-10;
            }
          }
        }

        .mobile-card-second-row {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: auto auto 10px auto;

          .cell {
            margin: 5px auto;

            @include small {
              width: 150px !important;
              max-width: 150px !important;
              min-width: 150px !important;
            }

            @include little {
              width: 150px !important;
              max-width: 50% !important;
              min-width: 129px !important;
            }

            &:first-child, &:nth-child(2) {
              font-size: 18px !important;
              font-weight: bold !important;
              background-color: transparent;
              line-height: 18px;

              & .tdx-chip {
                justify-content: start;
              }
            }

            &.col-zakazat {
              .bg-green {
                margin: 0;
                background-color: $tdx-green-font;
                color: white;

                @include little {
                  width: 100%;
                }
              }
            }

            .tdx-chip {
              background-color: transparent;

              span {
                font-size: 16px;
                font-weight: bold;
                line-height: 18px;
                padding: 0;
              }
            }

            &.green {
              background-color: $tdx-green-alpha-10;
            }

            &.red {
              background-color: $tdx-red-alpha-10;
            }
          }
        }

        .mobile-card-container {
          width: 95%;
          margin: 10px auto;

          &:first-child:not(:last-child) {
            margin-bottom: 0;
          }

          &:last-child:not(:first-child) {
            margin-top: 0;
          }

          .col-status {
            display: flex;
            gap: 5px;
            flex-wrap: wrap;
            width: calc(100% - 12%) !important;
            max-width: inherit !important;
            min-width: inherit !important;
            flex-direction: column;

            & > button {
              height: 54px;
            }

            & > .tdx-button {
              height: 54px;
            }

            .counter {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }

        &.disabled {
          opacity: 0.3;
          pointer-events: none;
        }
      }

      .row-toggle-wrapper {
        display: grid;
        grid-template-rows: 0fr;
        transition: grid-template-rows 0.3s ease-out;
        z-index:0;
        position:relative;

        .row-toggle {
          overflow: hidden;

          .product {
            .product-col {
              border:none;

              .total-price {
                font-weight:bold;
                font-size:1.2em;
              }
            }
          }
        }
      }

      &.show {
        .row-toggle-wrapper {
          grid-template-rows: 1fr;
        }
      }

    }

    .row{
      &.error {
        .icon {
          color: $tdx-red-alpha-70;
        }

        .col-summa {
          color: $tdx-red;
        }
      }
    }

  }

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}