@import "../../variables";
@import "../../mixins";

.tdx-refusals-page {
  padding: 24px 0 60px 0;

  h1 {
    margin-bottom: 16px;
  }
}
