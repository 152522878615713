@import "../../variables";
@import "../../mixins";

.tdx-catalog-page {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  background: $tdx-white;

  @include small {
    width: 95%;
    margin: 0 auto;
  }

  & > .wrapper {
    h1 {
      margin: 13px 0;

      @include little {
       text-align: center;
      }
    }
  }

  .catalog-container {
    position: relative;
    display: flex;
    align-items: flex-start;
    padding: 0 29px 59px 29px;

    @include little {
      flex-direction: column;
    }
    @include  small {
      padding: 0;
    }

    &>.content {
      width: 100%;
      overflow-x: hidden;

      .d-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;
        flex-wrap: wrap;
      }
    }

    .tdx-table-container {
      .row {

        @include small {
          background-color: $tdx-light-gray;
          border-radius: $tdx-btn-border-radius;
        }
        .content {
          .checkbox-container {
            min-width: 450px;
            gap: 10px;
            display: flex;
            justify-content: flex-start;

            @include little {
              min-width: 100%;
              flex-wrap: wrap;
              gap: 0;
            }

            & label {
              margin-bottom: 5px
            }
          }
        }

        .titles-wrapper {
          display: flex;
          gap: 10px;
          align-items: center;

          .titles {
            display: flex;
            flex-direction: column;


            @include small {
              text-align: center;
              height: 100px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              width: 100%;
            }

            @include little {
              height: 150px;
            }

            .subtitle-1 {
              color: $tdx-black-alpha-60;
            }
          }

          .icon-popper {
            svg {
              font-size: 1.57rem;
            }

            @include small {
              display:none;
            }
          }
        }

        .tdx-button.large.bg-blue,
        .tdx-button.large.bg-yellow-light,
        .tdx-button.large.bg-gray,
        .tdx-button.large.bg-green {
          height: 58px;
          max-height: 58px;
          min-width: 180px;
          flex: 1;

          @include small {
            flex-direction: column;
            width: 100%;
          }

          &:not(:last-child) {
            margin-right: 5px;

            @include small {
              margin-right: 0;
              margin-bottom: 5px;
            }
          }
        }

        .tdx-button.counter {
          position: relative;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          padding: 6px;
          transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

          @include small {
            margin-bottom: 0 !important;
            padding: 5px;
            min-width: 100% !important;
          }

          .counter {
            margin: 0 10px;

            @include small {
              margin: 0
            }
          }
        }
      }
      .table-content {
        .cell {
          &.body-1 {
            &:first-child {

              @include small {
                display: none;
              }
            }

            &.col-unknown {

              @include little  {
                padding: 0;
              }
            }
          }
        }
        @include small {
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
        }
        .row-wrapper {

          @include small {
            width: 49%;
            border-radius: 10px;
            background-color: $tdx-light-gray;
            margin-bottom: 10px;
          }

          & .col-opisanie {
            @include small{
            display: none;
            }
          }
        }
      }
    }
  }
}
