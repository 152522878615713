@import "../../variables";
@import "../../mixins";

.users-tab {
  .user-accordion {
    margin: 0 !important;
    border-radius: 0 !important;
    box-shadow: none !important;

    &::before {
      display: none;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $tdx-gray;
    }

    .summary-block {
      min-height: 48px;
      display: flex;
      justify-content: space-between;
      padding: 10px 20px;

      .summary-button {
        min-height: 28px;
        padding: 0;
        text-decoration: underline;
        justify-content: flex-start;
        flex-grow: 1;

        &.Mui-expanded {
          text-decoration: none;
        }

        .MuiSvgIcon-root {
          color: $tdx-black-alpha-87;
        }

        .MuiAccordionSummary-content {
          margin: 0 !important;
          flex-grow: 0;
          :before {
            content: none;
          }
        }
      }
    }

    .MuiAccordionDetails-root {
      padding: 13px 20px;
      background-color: $tdx-black-alpha-2;

      .body-row {
        &:first-child {
          border-bottom: 1px solid $tdx-gray;
          padding-bottom: 14px;
        }

        .row-container {
          display: flex;

          @include little {
            flex-direction: column;
            flex-wrap: wrap;
            height: 130px;
          }

          &:not(:last-child) {
            margin-bottom: 9px;
          }

          .row-item {
            width: 25%;

            @include little {
              width: 45%;
              margin-bottom: 7px;
            }

            &.counterparties {
              width: auto;
              padding-top: 14px;

              .title {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 6px;

                .body-2 {
                  color: $tdx-black-alpha-60;
                }

                .edit-button {
                  margin-left: 4px;
                }
              }

              .counterparties-list {
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                  &:not(:last-child) {
                    margin-bottom: 6px;
                  }

                  .body-1 {
                    color: $tdx-black-alpha-87;
                  }
                }
              }
            }

            .body-1 {
              font-weight: 400 !important;
            }
          }
        }
        &:last-child {

          .row-container {
            height: 100%;
          }
        }
      }
    }

    .edit-button {
      padding: 8px;

      .MuiSvgIcon-root {
        font-size: 12px;
        color: $tdx-black-alpha-87;
      }
    }
  }

  .pagination {
    height: 62px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include small {
      height: 100%;
      padding-top: 10px;
    }
  }
}
