@import "../variables";

.tdx-circular-center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.tdx-circular-progress {
  color: $tdx-red !important;
}

.tdx-page-loader {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  backdrop-filter: blur(5px);
}
