@import "header";
@import "footer";

@import "order-window/index";
@import "ProductsTable";

@import "tdx-modal-window";
@import "tdx-form";

@import "popper";
@import "accordion";
@import "auction";


a.logo {
  width:auto;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.hide {
    opacity: 0;
    pointer-events: none;
  }

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}
footer {
  .contacts-wrapper {
    .logo {
      &.hide {
        display:none;
      }
    }
  }
}