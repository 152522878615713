@import "../variables";
@import "../mixins";

.header {
  background: #fff;
  z-index: 10;


  .header-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 0;

    @include x-min {
      width:95%;
    }

    .currency-info {
      display: flex;
      cursor: default;
      color: $tdx-black-alpha-60;
      white-space: nowrap;
      gap: 10px;

      & > span {
        margin-left: 10px;
      }

      .updateProposal {
        @include little {
          display: none;
        }
      }
    }

    .search-container {
      display: flex;
      align-items: center;
      margin-left: 30px;

      @include little {
        width: 100%;
        margin: 0 auto;
        justify-content: space-between;
      }

      .tdx-search-input {
        margin-right: 8px;

        &.Mui-disabled {
          opacity: 0.5;
          pointer-events: auto;
          cursor: not-allowed;

          input {
            cursor: inherit;
          }
        }

      }
      .hiddenHelpService {
        display: none;
        @include little {
          display: inline-flex ;

          /*.tdx-button__content {
            display:none;
          }*/
        }
      }
      .askPrice, .currency-info {
        @include little {
          display: none;
        }
      }
      .tdx-search-input-container {
        @include x-min {
          display: none;
        }
      }
      .tdx-button {
        color: $tdx-black-alpha-60;
        background-color: transparent;
        margin-right: 16px;

        @include little {
          margin-right: unset;
        }

        &:hover {
          transition: none;
          -webkit-transition: none;
          color: $tdx-black-alpha-87;
        }
      }

      .profile {
        display: flex;
        align-items: center;

        .avatar {
          height: 32px;
          width: 32px;
          margin-right: 6px;
        }

        .MuiSvgIcon-root {
          cursor: pointer;
          font-size: 2rem;
          padding: 8px;
        }

        .title {
          font-weight: 700 !important;
          color: $tdx-black-alpha-60;
          text-decoration: none;

          &:hover {
            color: $tdx-black-alpha-87;
          }
        }
      }

    }
  }

  .header-border {
    border-bottom: 1px solid $tdx-black-alpha-4;
  }

  #burgerMenu {
    height: 100%;
  }

  .header-navigation {
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

     @include x-min {
       width: 95%;
       margin: 0 auto;
     }

    .logo-block {
      display:flex;
      align-items: center;
      gap: 15px;
    }

    #burgerMenu  {
      height: 65px;
      padding: 0;
      min-width: unset;

      @include x-min {
        height: 100%;
        min-width: 54px;
        max-width: 54px;
      }

      & .MuiButton-startIcon {
        width: 100%;
        height: 100%;
        margin-right: 0;

        @include x-min {
          margin:0;
        }

        svg {
          width: 80%;
          height: 80%;
          margin: auto;
        }
      }
    }

    .logo {
      svg {
        @include x-min {
          height: 50px;
          margin: auto;
        }
      }
    }

    .links-container {
      display: flex;
      align-items: center;

      .link {
        cursor:pointer;

        &:not(:first-child) {
          margin-left: 24px;
          @include x-min {
            margin-left: 15px;
          }
        }
      }

      .header-link-menu {

        button.tdx-nav-link {
          display: flex;
          align-items: center;
          border: 0;
          padding: 0;
          background: none;
          outline: none !important;
          cursor: pointer;

          & * {
            cursor: pointer;
          }
        }
      }

      //.MuiButton-root.tdx-nav-link {
      //  min-width: auto;
      //  color: $tdx-black-alpha-87 !important;
      //
      //  .MuiButton-endIcon {
      //    margin: 0;
      //  }
      //}
    }

    .tdx-buffer-modal {
      h5 {
        cursor: pointer;
      }
    }

    .tdx-nav-catalog {
      .csv-menu {
        margin-right: 10px;
      }

      svg.arrow-down {
        transition: 300ms;
      }

      &.active {
        pointer-events: all;

        svg.arrow-down {
          transform: rotate(180deg);
        }
      }
    }

    .notification {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 52px;

      .MuiBadge-dot {
        background: $tdx-green;
      }

      .MuiSvgIcon-root {
        color: rgba(0, 0, 0, 0.2);
      }
    }
    .logoMenu {
      background: transparent;
    }
    .pc-menu {
      @include little {
        display: none;
      }
    }
    .mobile-menu {
      display: none;

      @include little {
        display: block;
        order: -1;
      }
    }
    .combineIconsForMobile {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 100%;

      & button {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        min-width:64px !important;

        & span {
          width: 30px;
          height: 30px;
          margin: auto;

          svg {
            height: 90%;
            width:100%;
          }
        }

      }
      .tdx-profile-balance {

        @include x-min {
          margin-left: 0;
          padding: 0;
          background: unset !important;
        }

        svg {
          @include x-min{
            width: 30px;
            height: 30px;
            color: $tdx-black-alpha-60;
          }
        }

        .balance-notify {
          @include x-min{
            min-width: 15px !important;
            margin-top: -20px;
            margin-left: 0;
          }

          svg {
            @include x-min{
              width: 15px;
              height: 15px;
              color: $tdx-black-alpha-60;
            }
          }
        }

        h5 {
          @include little {
            display: none;
          }
        }
      }
    }
    .searchIcon {
      background: transparent;
      display: none;

      @include x-min {
        display: block;
      }
    }

  }

  .header-notify {
    display: flex;
    flex-direction: column;

    .header-notify-item {
      padding: 5px 15px;
      text-align: center;
      font-size: 0.9rem;

      &.error {
        background: $tdx-red-alpha-70;
        color: $tdx-white;

        * {
          color: $tdx-white;
        }
      }

      &.success {
        background: $tdx-green-alpha-70;
        color: $tdx-white;

        * {
          color: $tdx-white;
        }
      }

      &.info {
        background: $tdx-yellow-alpha-70;
        //color: $tdx-white;
        color: $tdx-black;

        * {
          //color: $tdx-white;
          color: $tdx-black;
        }
      }
    }
  }
}

#header-popper {
  .MuiPaper-root {
    margin-top: 9px;

    .MuiSvgIcon-root {
      margin-right: 10px;
    }

    .MuiListItem-root {
      padding: 8px 12px;

      .body-1 {
        color: $tdx-black-alpha-87;
        text-decoration: none;

        &.light {
          font-weight: 300 !important;
        }
      }

      .tdx-button.large.bg-transparent {
        min-width: 0;
        padding: 0 10px;
        height: 30px;

        .MuiButton-startIcon {
          margin: 0;
        }
      }
    }
  }
}

#search-popper {
  z-index: 10;
}

.header-categories {

  .catalog-tabs {
    position: relative;
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    //overflow: auto hidden;

    .tab {
      //flex-shrink: 0;
      min-width: 10%;
      padding: 5px 6px;
      color: $tdx-black-alpha-87;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      border: 1px solid $tdx-black-alpha-4;
      background: none;
      cursor: pointer;
      transition: background-color 0.25s ease-in;

      &:not(:first-child) {
        border-left: 0;
      }

      @include hover {
        background: $tdx-black-alpha-12;
      }

      &.active {
        background: $tdx-black-alpha-12;
      }

      &.subtitle-1 {
        line-height: 20px !important;
      }
    }

    .arrow {
      display: flex;
      justify-content: center;
      align-items: center;

      .MuiSvgIcon-root {
        width: 0.9rem;
        height: 0.9rem;
      }
    }

    .MuiModal-root {
      position: absolute;
      height: 80vh;
      overflow: hidden;

      .MuiPaper-root {
        top: 35px !important;
      }
    }

    .tabs-indicator {
      position: absolute;
      height: 2px;
      bottom: 0;
      width: 0;
      transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      background-color: rgba(0, 0, 0, 0.87);
    }
  }
}

.header-link-menu-paper {
  margin-top: 8px;

  .MuiMenu-list {
    padding: 0;
  }

  .MuiMenuItem-root {
    @include hover {
      background-color: $tdx-black-alpha-12;
    }

    &.active {
      background: $tdx-black-alpha-12;
    }
  }
}

.tdx-catalog-menu {
  display: flex;
  position: relative;
  max-height: 0;
  background: $tdx-white;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  transition: max-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &.active {
    max-height: 700px;
  }

  .height-fix {
    width: 100%;
    display: flex;
  }

  .aside-catalog-menu {
    width: 20%;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    list-style: none;
    border-right: 1px solid $tdx-black-alpha-4;
    overflow: hidden auto;

    .aside-item {
      padding: 10px 20px 10px 40px;
      color: $tdx-black-alpha-87;
      font-weight: 500 !important;
      line-height: 19px !important;
      text-align: left;
      border-bottom: 1px solid $tdx-black-alpha-4;
      cursor: pointer;
      transition: background-color 0.25s ease-in;

      @include hover {
        background: $tdx-black-alpha-12;
      }

      &.active {
        background: $tdx-black-alpha-12;
      }
    }
  }

  .beside-catalog-menu {
    width: 80%;
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    //display: grid;
    //grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    overflow: hidden auto;

    .beside-item {
      height: 120px;
      width: 20%;
      display: flex;
      align-items: center;
      padding: 20px;
      border-right: 1px solid $tdx-black-alpha-4;
      border-bottom: 1px solid $tdx-black-alpha-4;
      text-decoration: none;
      color: $tdx-black-alpha-87;
      cursor: pointer;
      transition: background-color 0.25s ease-in;

      @include min {
        width: 33.33%;
      }

      @include medium {
        width: 25%;
      }

      @include hover {
        background-color: $tdx-black-alpha-12;
      }

      &.current {
        background-color: $tdx-black-alpha-12;
      }

      img {
        width: 48px;
        height: 48px;
        margin-right: 10px;
        flex-shrink: 0;
        font-weight: 500 !important;
        color: $tdx-black-alpha-60;
        object-fit: contain;
      }

      .info {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        .body-2 {
          font-weight: 700 !important;
          word-break: break-word;
        }

        .subtitle-1 {
          color: $tdx-black-alpha-60;
        }
      }
    }
  }
}

#header-popper {
    z-index: 10;
}

#menu-popper {
  margin-top: 10px;
  transition: 300ms;
  box-shadow: 0 0 20px -10px;

  .MuiPaper-elevation {
    background: $tdx-light-gray;
    box-shadow:none;
  }

  .menu-wrapper {
    margin: auto;
    text-align: center;

    @include x-min {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 300px;
    }

    & div:has(.price) {
      @include x-min {
        display: none;
      }
      @include little {
        display: block;
      }
    }

    .header-link-menu {
      display: flex;
      justify-content: center;

      button.tdx-nav-link {
        display: flex;
        align-items: center;
        border: 0;
        padding: 0;
        background: none;
        outline: none !important;
        cursor: pointer;

        & * {
          cursor: pointer;
        }
      }
    }
    .tdx-nav-link {
      display: flex;
      //justify-content: center;
      margin: 5px auto;
      align-items: center;
    }

    .backMenu {
      margin-left: 0;

      svg {
        transform: rotate(90deg);
      }
    }
  }
}
