@import "../variables";

.footer {
  padding: 30px 0 12px;
  border-top: 1px solid $tdx-black-alpha-4;
  margin: 0 5px;

  @include  little {
    padding: 20px;
  }

  .contacts-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;

    @include small {
      gap: 15px;
      align-items: flex-start;
      justify-content: space-around;
    }

    @include little {
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
    }

    &>a, &>p, &>div {
      @include little {
        margin-bottom: 0px;
        text-align: center;
      }
    }

    &.justify-center {
      justify-content: center;
    }

    .footerInfo {
      width: 80%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;

      @include little {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%
      }

      & > a, & > p, & > div {
        @include small {
          width: 40%
        }

        @include little {
          margin-bottom: 15px;
          text-align: center;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .footer-address {
      width: 270px;
      font-weight: 400 !important;
      color: $tdx-black-alpha-87;

      @include small {
        margin-bottom: 30px;
        width:40%
      }

      @include little {
        width:100%;
        margin-bottom:0;
      }

      span {
        display: block;

        &:first-child {
          margin-bottom: 10px;
           @include little {
             margin-bottom: 0;
           }
        }
      }
    }

    .footer-mob-contacts {
      width: 170px;

      @include small {
        width:40%
      }

      @include little  {
        width:100%
      }

      .body-1 {
        display: block;
        font-weight: 400 !important;
        color: $tdx-black-alpha-87;
        text-decoration: none;
        width:100%;

        &:hover {
          text-decoration: underline;
        }

        &:not(:last-child) {
          margin-bottom: 8px;

          @include little {
            margin-bottom: 0;
          }
        }
      }
    }

    .footer-E-contacts {

      a {
        text-decoration: none;
        text-align: center;
      }

      .body-1 {
        display: block;
        color: #365fb9;
        text-align:center;

        @include small {
          text-align: center;
        }

        @include little {
          margin-left: 0;
        }

        &:hover {
          text-decoration: underline;
        }
      }

      .MuiButton-root {
        width: 203px;
        margin-top: 6px;
      }
    }

    .footer-social-media-contacts {
      display: flex;
      gap: 11px;

      @include little {
        flex-direction: row;
      }
    }
  }

  .copyrights-and-policies {
    display: flex;
    justify-content: space-between;
    margin-top: 70px;
    border-top: 1px solid $tdx-light-gray;
    padding: 10px 0;

    @include little {
      flex-direction: column;
      align-items: center;
    }

    @include small {
      margin-top: 0px;
    }
    .body-1 {
      font-weight: 400 !important;
    }

    .policies-wrapper {
      display: flex;

      @include little {
        flex-direction: column;
      }

      .body-1 {
        color: $tdx-black-alpha-87;
        text-decoration: none;

        @include little {
          text-align: center;
          margin: 5px auto;
        }

        &:hover {
          text-decoration: underline;
        }

        &:not(:last-child) {
          margin-right: 40px;
          @include  little {
            margin-right: auto;
          }
        }
      }
    }
  }
}
