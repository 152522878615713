@import "../../variables";
@import "../../mixins";

.invalid-field {
  border-radius: 10px;
  border-color: red;
  border-width: 1px;
  border-style: solid;
}

.invalid-feedback {
  color: red;
}

.tdx-buffer-page {
  padding: 20px 0;

  &.wrapper {
    @include small {
      max-width: 95%;
    }
  }

  h1 {
    margin-bottom: 20px;

    @include small {
      text-align: center;
    }
  }

  .mobile-card-second-row {
    background-color: $tdx-light-gray;
    height: 105px;
    padding: 0px 10px;

    &:first-child, &:nth-child(2) {
       color: $tdx-green-font;
     }

    & .col-kolichestvo {
      width: 100px !important;
      max-width: 100px !important;
      min-width: 100px !important;
    }
  }

  .summary {
    margin-top: 15px;
    padding: 0 15px;
    line-height: 30px !important;
    font-weight: 400 !important;
    border-radius: 4px;
    background: $tdx-black-alpha-4;

    @include small {
      text-align: center;
    }
  }

  .extra-form {
    display: flex;

    .target-order {
      display: flex;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;

      .target-order-select {
        margin: 10px;
        border-radius: 5px;
        width: 200px;
      }
    }
  }

  .actions {
    padding-right: 36px;

    @include small {
      justify-content: center;
      padding-right: 0;
    }

    &>div {
      padding-left: 10px;
      padding-right: 10px;
    }

    & button {
      background-color: $tdx-green-font;
      color: white;

      @include small {
        min-width:unset !important;
      }
    }
  }

  .comment {
    display: inline-flex;
    flex-direction: column;
    margin-top: 15px;

    textarea {
      width: 320px;
      margin-top: 5px;
      padding: 10px;
      color: $tdx-black-alpha-87;
      border-radius: 6px;

      @include little{
        width: 300px;
      }

      &:focus {
        outline: none;
        border-color: $tdx-black-alpha-87 !important;
      }
    }
  }

  .clear-icon {
    color: $tdx-red;
  }

  .MuiGrid-item {
    .bg-green {
      @include small {
        background-color: $tdx-green-font;
        color: white;
      }
    }
  }

  .tdx-chip {
    width:100%;
  }
}

.add-product-by-sku {
  position: relative;
  margin-top: 20px;

  .add-product-form {
    margin-top: 10px;
    text-align: start;

    .body-2 {
      margin-bottom: 5px;
      font-weight: 400 !important;

      &.clear {
        color: $tdx-black-alpha-60;
        text-decoration: underline;
        width: 120px;

        &:hover {
          cursor: pointer;
        }

        &:active {
          color: $tdx-black-alpha-87;
        }
      }
    }

    .new-product-textarea {
      max-width: 100%;
      width: 569px;
      height: 165px;
      padding: 10px;
      color: $tdx-black-alpha-87;
      border-radius: 6px;

      &:focus {
        outline: none;
        border-color: $tdx-black-alpha-87 !important;
      }
    }

    .submit-container {
      display: flex;
      justify-content: flex-start;
      padding-top: 10px;

      .tdx-button {
        &:first-child {
          margin-right: 23px;
        }
      }
    }
  }
}