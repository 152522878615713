@import "../variables";

.accordeon-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px #0000001a;
  border-radius: 4px;
  position: relative;
  background-color: #fcfcfc;
  overflow:hidden;

  .accordeon-item__trigger {
    padding: 20px;
    border: 1px solid #e0e0e3;
    font-weight: bold;
    cursor:pointer;
  }

  .accordeon-item__content-expand {
    display: grid;
    grid-template-rows: 0fr;
    transition: all 200ms ease-in-out;

    .accordeon-item__content {
      overflow: hidden;
      border: 1px solid #e0e0e3;
      border-top: 0;
      padding: 0 20px;
      transition: 200ms;

      img {
        width: 80%;

        @include small {
          width: 100%;
        }
      }

      ul {
        margin: 1em 0;

        @include small {
          padding-left: 20px;
        }

        li {
          margin-bottom: 10px;
        }

        li:not(:last-child) {
          margin-bottom: 5px;
        }
      }

      ol {
        @include small {
          padding-left: 20px;
        }

        li {
          margin-bottom: 10px;
        }
      }

      p {
        margin: 15px 0;
      }
    }
  }

  .accordeon-item__input {
    position: absolute;
    top:0;
    left:  0;
    opacity: 0;

    &:checked ~ .accordeon-item__content-expand {
      grid-template-rows: 1fr;

      .accordeon-item__content {
        padding: 20px;
      }
    }
  }
}