@import "../../variables";
@import "../../mixins";

.users-settings-counterparty-editing {
  position: relative;

  .tdx-outlined-input {
    width: 300px;
    margin-bottom: 30px;

    &.select {
      .MuiOutlinedInput-root {
        font-size: 16px;
        line-height: 33px;
      }
    }
  }
}
