@import "../../variables";
@import "../../mixins";

.users-settings {
  padding-top: 24px;
  padding-bottom: 60px;
  background: $tdx-white;
  color: $tdx-black-alpha-87;

  @include small {
    width: 95%;
    margin: 0 auto;
  }

  & h1 {
    @include small {
      text-align: center;
    }
  }

  .form {
    margin-top: 24px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border: 1px solid $tdx-gray;
    background: #fff;
    overflow: hidden;

    .form-header {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $tdx-gray;

      @include little {
        flex-direction: column;
      }

      .MuiTabs-root {
        min-height: auto;

        @include little {
           order: 2;
        }

        .tab {
          width: 168px;
          min-height: auto;
          min-width: auto;
          padding: 10px;
          margin: 0;
          color: $tdx-black-alpha-60;

          @include little {
             width: 130px;
          }

          &.Mui-selected {
            color: $tdx-black-alpha-87;
            font-weight: 700 !important;
          }
        }

        .MuiTabs-indicator {
          background-color: $tdx-black-alpha-87;
        }
      }

      .search-counterparties-input {
        margin: 3px 3px;
        max-width: 350px;

        @include small {
          display: none;
        }
      }

      .tdx-button {
        height: auto;
        border-radius: 0;

        @include little {
          justify-content: flex-end;
        }
      }
    }

    .form-body {
      .height-fix {
        max-height: 650px;
        overflow: hidden auto;
      }
    }
  }
}

.MuiModal-root {
  &.users-settings-modal {
    .tdx-order-nested-window-modal {
      &.MuiPaper-root {
        width: 1200px;

        &:focus {
          outline: none;
        }
      }
    }
  }

  &.users-settings-modal-narrow {
    .tdx-order-nested-window-modal {
      &.MuiPaper-root {
        width: 600px;

        &:focus {
          outline: none;
        }

        .MuiOutlinedInput-root {
          font-size: 16px;
        }
      }
    }
  }
}
