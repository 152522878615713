.categories-container {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0;
    gap: 10px;

    .category-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      margin-right: 20px;

      .search-category-link {
        color: $tdx-black;
        margin-left: 5px;
        text-decoration: none;
        vertical-align: middle;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    @include min {
      padding-bottom: 20px;
    }
}

.products-table {
  .tdx-table-container {
    .cell:last-child {
      justify-content: flex-end;

      @include small {
        padding: 0;
      }
    }

    .table {
      .cell {
        .tdx-button {
          &.large {
            height: 55px;
            min-width: 180px;
            flex: 1 1;
          }

          &.counter {
            position: relative;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 6px;
            transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          }

          &.large:not(:last-child) {
            margin-right:5px;
          }
        }

        .btn-waitlist {
          font-size:12px !important;
        }

        button {
          &.clear-icon {
            padding: 3px;

            &:hover {
              background: none;
            }
          }
        }
      }
    }

    .row {
      .titles {
        display: flex;
        flex-direction: column;

        .subtitle-1 {
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }

    .pagination {
      ul {
        margin:0;
        padding:0;

        li {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}