@import "../../variables";
@import "../../mixins";

.copy-success {
  padding: 0 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .MuiSvgIcon-root {
    width: 50px;
    height: 50px;
    color: $tdx-green;
  }

  .body-2 {
    display: flex;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 16px;
    color: $tdx-black-alpha-60;
    line-height: 24px !important;
  }
}

.tdx-price-page {
  padding: 24px 0 60px 0;

  h1 {
    margin-bottom: 24px;

    @include small {
      text-align: center;
    }
  }

  &.wrapper {

    @include small {
      width: 95%;
      margin: 0 auto;
    }
  }

  .MuiGrid-container {
    @include little {
      flex-direction: column;
      width: 100%;
      margin: 0 auto;
    }

    .MuiGrid-item {

      @include little {
        width: 100%;
        max-width: 100%;
        margin: 0 auto;
        padding-left: 0;
      }

      & h4{
        @include little{
          font-size: 16px;
        }
      }
    }
  }
}

.tdx-price-page-generate {
  text-align: center;
  color: $tdx-black-alpha-87;

  .progress {
    margin: 20px 0;
    height: 10px;
    border-radius: 4px;
    background-color: $tdx-green-alpha-10;

    span {
      background-color: $tdx-green;
    }
  }

  .error-icon {
    font-size: 50px;
    color: $tdx-red;
  }

  .done-icon {
    font-size: 50px;
    color: $tdx-green;
  }
}

.price-settings-editing {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  gap: 10px;

  .columns-settings {
    max-width: 650px;
    padding: 20px 10px;
    background: $tdx-black-alpha-4;
    border-radius: 6px;

    @include little {
      max-width: 310px;
      grid-template-columns: repeat(2, 1fr);
    }

    &.flex {
      display:flex;
      flex-wrap:wrap;
      gap: 10px;
    }

    &.grid {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 10px;
    }

    .column {
      width: auto;
      margin-top: 18px;

      .MuiOutlinedInput-root {
        font-size: 12px;

        .MuiSelect-select {
          padding: 3px 18px 3px 6px !important;
        }
      }
    }
  }

  .sections-settings {
    padding-top: 22px;

    h4 {
      margin-bottom: 14px;
      text-align: center;
    }

    .sections {
      max-height: 280px;
      padding: 14px 0;
      border-top: 1px solid $tdx-black-alpha-12;
      border-bottom: 1px solid $tdx-black-alpha-12;
      overflow: hidden scroll;

      .MuiFormControlLabel-root {
        padding-right: 10px;
        align-items: stretch;

        .MuiCheckbox-root {
          display: flex;
          align-items: flex-start;

          .MuiSvgIcon-root {
            width: 20px;
            height: 20px;
          }

          & + .MuiTypography-root {
            font-size: 12px;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      .general-sections {
        .checkboxes-container {
          max-height: 80px;
          display: flex;
          flex-flow: column wrap;
          align-content: flex-start;
          margin-top: 12px;
          transition: max-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          overflow: hidden;

          .MuiFormControlLabel-root {
            width: 33.3%;
          }
        }

        .tdx-button {
          width: 100%;
          justify-content: center;
          margin-top: 12px;
          font-size: 12px !important;

          svg {
            transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          }

          &.expanded {
            svg {
              transform: rotate(180deg);
            }
          }
        }
      }

      .additional-checkboxes {
        display: flex;
        flex-direction: column;
        margin-top: 12px;
      }
    }
  }

  .additional-settings {
    display: flex;
    flex-direction: column;
    padding-top: 12px;

    .row {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 15px;
      }

      .tdx-outlined-input.select {
        margin-top: 18px;
      }

      .body-1 {
        margin-left: 10px;
      }

      .body-2 {
        margin-left: 10px;
        margin-top: 18px;
        color: $tdx-black-alpha-60;
      }
    }
  }

  .submit-container {
    display: flex;
    justify-content: center;
    padding: 23px 0;
  }
}

.tdx-order-nested-window {
  &.price-settings {
    .tdx-order-nested-window-modal {
      top: 5% !important;
      max-height: 830px !important;
    }
  }

  &.copy-success {
    .MuiBackdrop-root {
      display: none;
    }

    .tdx-order-nested-window-modal {
      top: 20%;
      min-width: 320px;
      min-height: 140px;
      padding: 0 30px;
      border-radius: 10px;

      .close-button {
        display: none;
      }

      .modal-body {
        justify-content: space-around;

        svg {
          font-size: 50px;
          color: $tdx-green;
        }

        .body-2 {
          color: $tdx-black-alpha-60;
        }
      }
    }
  }
}
