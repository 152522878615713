@import "../../variables";

.tdx-auth-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 34px 0 122px;

  .auth-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 630px;
    padding: 25px;
    margin: 40px 0 25px;
    background: $tdx-black-alpha-4;
    border-radius: 8px;

    @include little {
      width: 95vw;
    }

    .inputs-container {
      width:100%;
      max-width: 318px;
      padding: 30px 0 35px;

      p.body-1 {
        cursor: pointer;
        color: $tdx-black-alpha-60;
        max-width: 110px;

        @include small {
          max-width: 100%;
        }

        &:hover {
          color: $tdx-black-alpha-87;
          transition: color 0.3s ease-in-out;
        }
      }

      .tdx-outlined-input {
        width: 100%;
        margin: 15px 0;

        &.select .MuiSelect-select {
          padding: 8px 24px 7px 10px !important;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          letter-spacing: 0.15px;
          color: rgba(0, 0, 0, 0.87) !important;
        }
      }

      .tdx-autocomplete {
        max-width:350px;
        width:100%;
      }
    }

    .captcha-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      button {
        padding: 0;
        margin: 0;
        border: 0;
        background: none;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .link-container {
    color: $tdx-black-alpha-87;
    text-align: center;

    p.body-1 {
      font-weight: 400 !important;
    }

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.tdx-selector-menu.registration-selector-menu {
  .MuiList-root {
    padding: 0;
  }

  .tdx-select-item {
    padding: 5px 10px !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 150% !important;
    letter-spacing: 0.15px !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
}
