@import "../../variables";
@import "../../mixins";

.tdx-orders {
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  background: $tdx-white;

  @include small {
    width: 95%;
    margin: 0 auto;
    padding: 20px 0;
  }

  .content {
    label {
      .MuiTypography-root {
        font-weight: 700 !important;
        font-size: 10px !important;
        line-height: 14px !important;
      }
    }
  }

  .clear-icon {
    color: $tdx-red;
  }

  .local-shipping-icon {
    color: $tdx-blue;
  }

  .description-icon {
    color: $tdx-green;
  }

  .more-vert-icon {
    color: $tdx-black;
  }

  .info {
    display: flex;
    justify-content: space-between;
    //align-items: center;

    @include little {
      flex-direction: column;
    }

    &.wrapper {
      @include little {
        padding: 0;
      }
    }
    h1 {
      @include little{
        text-align: center;
        margin-bottom: 15px;
      }
    }

    .tdx-aside-filters-container {
      @include small {
        margin-right: 0;
      }
    }

    .tdx-button {
      &.medium {
        &.bg-red{
          @include little {
            max-width: 30%;
            text-align: center;
            margin-top: 10px;
          }
          @include extra-small {
            max-width: calc(50% - 5px);
            width: 100%;
            min-width: unset;
          }
        }
      }
    }

    .btn-wrapper {
      @include little {
        max-width: 30%;
      }
      @include extra-small {
        max-width: calc(50% - 5px);
        width: 100%;
        min-width: unset;
      }

      .tdx-button {
        &.medium {
          &.bg-red{
            @include little {
              max-width: 100%;
              text-align: center;
              margin-top: 10px;
            }
            @include extra-small {
              max-width: 100%;
              width: 100%;
              min-width: unset;
            }
          }
        }
      }
    }

    .icon {
      font-size: 1.2rem;
    }

    .actions-container {
      display: flex;

      .action {
        display: flex;
        align-items: center;

        &:not(:last-child) {
          margin-right: 25px;
        }

        .subtitle-1 {
          margin-left: 5px;

          @include small{
            font-size: 13px !important;
          }
        }
      }
    }

    .orders-buttons {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }

    .summary {
      padding: 0 15px;
      line-height: 30px !important;
      font-weight: 400 !important;
      border-radius: 4px;
      background: $tdx-black-alpha-4;
    }
  }

  .table-container {
    width: 100%;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 16px 0;

    .tdx-table-settings-wrapper {
      button {
        padding: 0.19rem 0;
      }
    }

    .tdx-table-mobile-container {
      .table-mobile {
        .mobile-card  {
          .row {
            &.header {
              min-height: 65px;
              max-height: 65px;
            }
            .mobile-card-first-row {
              flex-direction: column;

              .mobile-card-checkbox-column {
                width: 100% !important;
                max-width: 100% !important;
              }

              .mobile-card-wrapper {
                margin-left: 20%;
                width: 80%;

                @include little {
                  margin-left: 10%;
                  width: 90%;
                }

                .mobile-card-cell {
                  margin-top: 7px;
                }

                .col-status {
                  color: #6A6A6A;
                }

              }

            }

            .mobile-card-second-row {
              .cell {
                margin-left: 20%;
                width: 80% !important;
                max-width: 80% !important;

                @include little {
                  margin-left: 10%;
                  width: 90% !important;
                  max-width: 90% !important;
                }

                &:first-child {
                  font-weight: normal !important;
                }
              }
            }
          }
          .row-toggle {
            .product {
              flex-direction: row;
              justify-content: start;
              align-items: start;
              margin: 10px auto;
              width: 90%;

              &:not(:last-child){
                border-bottom: 1px solid #bebebe;
                padding-bottom: 10px;
              }

              .cell {
                margin-top: 7px;
              }

              .clear-icon {
                display:  block;
              }

              .more-vert-icon {
                display:  none;
              }
            }
          }

          &.show {
            .row-toggle {
              border-top: 1px solid $tdx-black-alpha-20;
            }
          }
        }
      }
    }
  }

}


.orders-menu-item {
  justify-content: space-between !important;

  @include hover {
    background-color: $tdx-black-alpha-12;
  }
}
