@import "../variables";

.tdx-form {
  position: relative;

  .inputs-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 30px;

    .tdx-outlined-input {
      width:100%;
    }
    .tdx-radio-group {
      width:100%;
      margin-bottom: 15px;
    }
  }

  .submit-container {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $tdx-black-alpha-2;

    .buttons {
      position: relative;

      .tdx-button {
        margin-right: 30px;
      }
    }
  }

  .container {
    padding: 30px 0;

    &:not(:last-child) {
      border-bottom: 1px solid $tdx-gray;
    }
  }

  .row {
    display: flex;

     @include small {
       flex-direction: column;
     }

    &:nth-child(2) {
      .input-wide:not(:last-child) {
        @include small {
          margin-bottom: 10px
        }
      }
    }
  }

  .column {
    display: flex;
    flex-direction: column;

    @include small {
       width: 100%;
     }

    & + .row {
      margin-top: 30px;
    }
  }

  .input-wrapper {

    @include small {
      margin-bottom: 10px;
    }

    & + .input-wrapper {
      margin-left: 50px;

      @include small{
        margin-left: 0;
      }
    }

    & > .mb:not(:last-child) {
      margin-bottom: 10px;
    }

    .input-narrow {
      width: 100%;

      @include small {
        width: 100%;
      }
    }

    .input-wide {
      width: 100%;

      @include small {
        width: 100%;
      }

      &.select {
        .MuiOutlinedInput-root {
          font-size: 16px;
          line-height: 35px;
        }
      }
    }
  }

  &.matching-form {
    .inputs-container {
      padding: 0;
      align-items: start;
    }

    .submit-container {
      background: unset;
      justify-content: left;

      @include small  {
        justify-content: center;
      }
    }
  }

  &.order-delivery-data {
    .input-wrapper {
      margin-bottom: 30px;

      .row {
        gap: 10px;
      }
    }
  }
}

.move-to-shipment {
  .tdx-form {
    background: #F6F6F6;
    border-radius: 7px;
    margin: 10px 0;
    padding: 20px 15px;

    .location {
      display:flex;
      flex-wrap: wrap;
      gap: 20px;
    }

    .inputs-container {
      align-items: start;
      padding: 0;

      .tdx-outlined-input {
        &.select-delivery {
          margin: 0 0 15px;
          width: fit-content;

          .MuiAutocomplete-input {
            width: 100%;
          }

          .MuiInputLabel-root {
            display:none;
          }

          .MuiSelect-select {
            padding: 8px 24px 7px 10px !important;
          }
        }
      }

      .tdx-autocomplete {
        &.select-delivery {
          margin: 0 0 15px;
          
          .MuiAutocomplete-input {
            width: 100%;
          }
        }
      }

      .tdx-date-time-input {
        gap: 15px;

        .tdx-outlined-input {
          margin: 30px 0 15px;

          label {
            transform: translate(0, -26px) scale(1);
            font-weight: 500;
          }

          .MuiOutlinedInput-notchedOutline {
            legend {
              span {
                display:none;
              }
            }
          }

          &.select {
            .MuiInputBase-root {
              height: 100%;
            }
          }
        }
      }

      .tdx-placeholder {
        margin-bottom: 15px;
      }
    }

    .submit-container {
      background: none;
      justify-content: start;
      padding: 0;
      height: unset;

      .buttons {
        .tdx-button {
          margin-right: 10px;
        }
      }
    }

    .MuiTypography-root, .MuiInputBase-input {
      font-size: 16px;
    }
  }
}
