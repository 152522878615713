@import "../variables";

#balance-popper {
  max-width: 450px;
  z-index:10;

  &.table-error {
    max-width: 280px;
  }

  .MuiPaper-root {
    padding: 10px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    .popper-link {
      text-align: end;
      color: $tdx-blue;
    }

    ul {
      padding: 0;

      li {
        padding: 8px 0;
        gap: 5px;

        .info-outlined-icon {
          color: $tdx-red-alpha-70;
        }
      }
    }
  }
}

#buffer-popper {
  max-width: 640px;
  z-index:10;

  .MuiPaper-root {
    padding: 10px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    @include little {
      max-height: 65vh;
      overflow: hidden scroll;
    }

    .buffer-footer {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 10px;

      @include little {
        justify-content: center;
      }
    }

    .popper-link {
      text-align: end;
      color: $tdx-blue;
    }

    ul {
      padding: 0;
      margin: 10px 0;
      max-height: 400px;
      overflow-y: auto;

      li {
        padding: 0 10px 0 0;
        gap: 0;

        &:first-child {
          .buffer-modal-item {
            border-top: 1px solid #e0e0e3;
          }
        }

        .buffer-modal-item {
          min-height: 50px;
          display: flex;
          align-items: center;
          width: 100%;
          border-bottom: 1px solid #e0e0e3;
          gap: 10px;
          padding: 5px 0;

          @include little {
            flex-wrap: wrap;
          }

          a {
            color: rgba(0, 0, 0, 0.87);
            text-decoration: none;
            word-break: break-word;
            width: 220px;

            &:hover {
              text-decoration: underline;
            }

            @include little {
              width: calc(100% - 60px);
            }
          }

          img {
            min-width: 50px;
            min-height: 50px;
            max-width: 50px;
            max-height: 50px;
            object-fit: contain;
          }

          .sku {
            width: 115px;
            text-align: center;
            text-overflow: ellipsis;
            overflow: hidden;

            @include little {
              display: none;
            }
          }

          .total {
            text-align: right;
            white-space: nowrap;
            width: 95px;

            @include little {
              width: calc(100%/2 - 5px);
            }
          }

          .quantity {
            margin-top: 12px;

            @include little {
              width: calc(100%/2 - 5px);
            }
          }
        }

      }
    }
  }
}

#waiting-list-popper {
  max-width: 450px;
  z-index:10;

  &.table-error {
    max-width: 280px;
  }

  .MuiPaper-root {
    padding: 10px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    .popper-link {
      text-align: end;
      color: $tdx-blue;
    }

    ul {
      padding: 0;

      li {
        padding: 8px 0;
        gap: 5px;

        .info-outlined-icon {
          color: $tdx-green-alpha-70;
        }
      }
    }
  }
}

.icon-popper-content {
    padding: 10px 20px;
    font-size: 14px;
}