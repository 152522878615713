@import "../../variables";
@import "../../mixins";

.tdx-order-window {
  display: flex;
  align-items: center;
  justify-content: center;

  & > .MuiBox-root {
    position: relative;
    width: 95%;
    height: 95%;
    background-color: $tdx-white;
    margin:auto;
    border-radius:10px;
    overflow: hidden;
  }

  .close-button {
    position: absolute;
    right: 30px;
    top: 30px;
    padding: 4px;
    background-color: $tdx-black-alpha-4;
    z-index: 1;

    .MuiSvgIcon-root {
      font-size: 28px;
      color: rgba(0, 0, 0, 0.54);
    }

    &:hover {
      background-color: $tdx-black-alpha-12;

      .MuiSvgIcon-root {
        color: $tdx-black-alpha-75;
      }
    }
  }

  .order-wrapper {
    display: flex;
    flex-direction: column;
    height: calc(100% - 30px);
    padding-top: 30px;
    overflow: hidden;

    @include small {
      overflow-y:auto;
    }

    .order-info-wrapper {
      .order-status-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap:20px;
        padding-right: 44px;

        @include min {
          width: 95%;
          padding-right:0;
        }

        @include small {
          width: 95%;
          margin: 0 auto;
          flex-wrap: wrap;
          gap: 8px;
        }

        & h1 {
          @include small {
            order: 0;
            width: 100%;
            text-align: center;
            margin-bottom: 10px;
          }
        }
        .tdx-chip {
          margin: 0;
          order: 1;

          @include small {
            margin: 0;
            max-width: 50%;
            height: 100% !important;

            span {
              white-space: normal;
              padding: 5px 12px;
              text-align: center;
            }
          }
        }

        .status-label {
          margin-left: -8px;
          margin-right: 16px;

          span {
            font-weight: 400 !important;
          }
        }

        .tdx-button:not(:last-child) {
          margin-right: 16px;
        }

        & > h5 {
          margin-left: auto;
          color: $tdx-black-alpha-60;

          @include little {
            order: 2;
            font-weight: bold;
            max-width: 50%;
            display: flex;
            flex-direction: column;
          }

          span {
            color: $tdx-black-alpha-87;
            font-weight: normal;
          }
        }

        .order-actions {
          display: flex;
          flex-wrap: wrap;
          gap: 5px;

          @include little {
            width:100%;
            order: 3;
            gap: 5px;
          }

          .tdx-button {
            margin-right: unset;
          }
        }
      }

      .order-info-container {
        margin-bottom: 10px;

        .labels-row {
          display: flex;
          flex-flow: row wrap;
          margin-bottom: 10px;

          @include small {
            width: 95%;
            margin: 0 auto 10px;
          }

          @include little {
            flex-direction: column;

          }

          .label {
            max-width: 470px;
            padding-top: 10px;
            padding-right: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: $tdx-black-alpha-60;

            .value {
              color: $tdx-black-alpha-87;

              @include little {
                font-weight: bold;
              }

              &.red {
                color: $tdx-red;
              }
            }
          }
        }

        .total-info {
          padding: 6px 12px;
          background-color: $tdx-black-alpha-4;
          border-radius: $tdx-btn-border-radius;
          font-weight: 400 !important;

          @include small {
            padding: 20px;
          }

          @include little {
            display: flex;
            flex-direction: column;
          }

          span span {
            font-weight: 700 !important;
          }
        }
      }
    }

    .overflow-container {
      position: relative;
      padding-bottom: 10px;
      overflow: auto;

      @include small {
        overflow: visible;
      }

      .order-comment-container {
        display: flex;
        flex-wrap: wrap;
        gap: 0 10px;
        justify-content: space-between;
        margin: 10px auto;

        .order-actions {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
        }

        button {
          @include little {
            width:100%;
          }
        }
      }

      .comment {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        @include small {
          max-width: 67%;
        }

        @include little {
          width:100%;
          max-width: 100%;
          align-items: start;
          flex-direction:column;
        }

        textarea {
          width: 320px;
          height: 38px;
          margin-right: 10px;
          padding: 10px;
          color: $tdx-black-alpha-87;
          border-radius: 6px;

          @include little {
            width: 100%;
            margin-bottom: 10px;
          }

          &:focus {
            outline: none;
            border-color: $tdx-black-alpha-87 !important;
          }
        }
      }

      .delivery {
        margin-bottom: 10px;
        padding: 6px 12px;
        background-color: $tdx-black-alpha-4;
        border-radius: $tdx-btn-border-radius;
        font-weight: 400 !important;

        @include small{
          padding: 20px;
        }

        & > p {
          margin-top: 5px;
        }
      }

      .tdx-table-container {
        .status-container {
          text-align: center;

          .stock-date {
            margin-top: 5px;
            color: $tdx-black-alpha-87;

            span {
              font-weight: 400 !important;
            }
          }
        }

        .confirm-quantity {
          display: flex;
          align-items: center;

          .reject {
            flex-shrink: 0;
            color: $tdx-red;
            text-decoration: line-through;

            & + span {
              margin: 0 10px;
            }
          }
        }
      }

      .tdx-table-mobile-container {
        width: 95%;
        margin: 0 auto;

        .mobile-card-wrapper{
          & .col-foto {
            display: none;
          }

          & .col-artikul-proizvoditelya {
            color: $tdx-black-alpha-30;
          }
        }

        .mobile-card-second-row {
          align-items: start;
          margin: 0 auto 10px 12%;

          & .col-artikul-iz-praisa {
            display: none;
          }


        }
        .table-mobile {
          .mobile-card {
            .row {
              .mobile-card-second-row {
                gap:5px;

                .cell {
                  margin: 0;
                  font-size: 16px !important;
                  font-weight: normal !important;

                  & .col-summa {
                    font-size: 18px !important;
                    font-weight: bold !important;
                  }
                }
              }
            }
          }
        }

      }

      .actions {
          gap: 10px;
      }

      .move-order {
        margin-top: 20px;

        .target-order {
          display: flex;
          align-items: center;
          padding-left: 0;

          .target-order-select {
            margin: 10px 10px 10px 0;
            border-radius: 5px;
            width: 250px;
          }
        }
      }
    }
  }

  .actions-wrapper {
    height: 64px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    box-shadow: 0px -4px 9px rgba(0, 0, 0, 0.1);

    .wrapper {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include small {
        width: 95%;
        margin: 0 auto;
      }

      .order-actions {
        .tdx-button {
          &:not(:last-child) {
            margin-right: 20px;
          }
        }
      }
    }
  }
}
