@import "../../variables";
@import "../../mixins";

.tdx-waiting-list-page {
  padding: 24px 0 60px 0;

  &.wrapper {
    @include small {
      width: 95%;
      margin: 0 auto;
      padding-bottom: 20px;
    }
  }

  .title-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    @include small {
      justify-content: center;
    }

    h1 {
      margin-bottom: 5px;

      @include small {
        text-align: center;
      }
    }

    .body-2 {
      font-weight: 400 !important;
    }
  }

  .tdx-table-container {

    .table {
      .header {
        @include small {
          display: none;
        }
      }

      .table-content {
        @include little {
          flex-direction: column;
        }
      }

      .row-wrapper {
        @include little {
          width: 100%;
        }
      }

      .cell:last-child {
        justify-content: flex-end;
      }

      .tdx-button.counter {
        @include small {
          margin-bottom: 0 !important;
          padding: 5px;
          min-width: 100% !important;
        }

        .counter {
          margin: 0 10px;

          @include small {
            margin: 0
          }
        }
      }

      .clear-icon {
        color: $tdx-red;

        &:hover {
          background: none;
        }
      }
    }

    .titles-wrapper {
      display: flex;
      gap: 10px;
      align-items: center;

      .icon-popper {
        svg {
          font-size: 1.57rem;
        }

        @include small {
          display:none;
        }
      }
    }
  }


  .table {
    .cell {
      .tdx-button {
        &.large {
          height: 55px;
          min-width: 180px;
          flex: 1 1;
        }

        &.counter {
          position: relative;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          padding: 6px;
          transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }

        &.large:not(:last-child) {
          margin-right: 5px;
        }
      }

      .btn-waitlist {
        font-size: 12px !important;
      }

      button {
        &.clear-icon {
          padding: 3px;

          &:hover {
            background: none;
          }
        }
      }
    }
  }

  .tdx-table-mobile-container {

    .table-mobile {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      @include little {
        flex-direction: column;
      }

      .mobile-card {
        .row {
          .mobile-card-first-row {
            gap: 5px;

            .mobile-card-checkbox-column {
              button {
                padding: 2px;

                svg {
                  color: $tdx-red;
                }
              }
            }

            .mobile-card-wrapper {
              & .item-image {
                width: 50%;
              }

              & .col-kod-tovara {
                color: $tdx-black-alpha-30;
              }
            }
          }

          .mobile-card-second-row {
            background-color: transparent;
            height: 100%;

            .cell {
              margin: 5px auto 5px 12%;

              &:first-child, &:nth-child(2) {
                font-weight: normal !important;
                color: $tdx-black-alpha-87;

              }

              &:nth-child(2) {
                color: $tdx-red;
                font-weight: bold !important;
              }
            }

            .tdx-button.counter {
              .counter {
                margin: 0 10px;

                @include small {
                  margin: 0
                }
              }
            }

            .clear-icon {
              color: $tdx-red;
              flex-wrap: wrap;
              max-width: 27%;

              &:hover {
                background: none;
              }

              .label {
                color: $tdx-black;
              }
            }

            .description-icon {
              color: $tdx-green;
              flex-wrap: wrap;
              max-width: 27%;

              .label {
                color: $tdx-black;
              }
            }

          }
        }
      }
    }
  }
}
