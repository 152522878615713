@import "../../variables";
@import "../../mixins";

.user-partners-editing {
  position: relative;
  height: 370px;
  display: flex;
  margin-bottom: 20px;

  .counterparties-list {
    height: 100%;
    width: 400px;
    margin-right: 60px;
    padding: 20px;
    background-color: $tdx-black-alpha-4;
    overflow: hidden auto;
    border-radius: 5px;

    & > .body-1 {
      margin-bottom: 15px;
    }

    .counterparty {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 5px;
      }

      .body-1 {
        font-weight: 400 !important;
        color: $tdx-black-alpha-87;
        text-decoration: underline;
      }

      .tdx-clear-icon {
        color: #d91f2a !important;

        &.MuiIconButton-root {
          padding: 3px;

          svg {
            font-size: 18px !important;
          }

          @include hover {
            background-color: $tdx-red-alpha-10;
          }

          &:disabled {
            svg {
              color: $tdx-black-alpha-12 !important;
            }
          }
        }
      }
    }
  }

  .autocomplete-container {
    height: 100%;
    width: 320px;

    .tdx-autocomplete {
      .MuiChip-root {
        border-radius: 4px;

        .MuiSvgIcon-root {
          display: none;
        }
      }
    }

    & > .body-1 {
      margin: 15px 0;
    }

    .autocomplete-values {
      height: calc(100% - 90px);
      overflow: hidden auto;

      .value {
        display: block;
        width: 100%;
        padding: 6px;
        background: $tdx-black-alpha-4;
        border-radius: 4px;

        &:not(:last-child) {
          margin-bottom: 5px;
        }
      }
    }
  }
}
