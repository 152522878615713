@import "../../variables";
@import "../../mixins";

.tdx-home-page {
  padding: 40px 0 60px 0;

  @include small {
    padding: 20px 0 35px 0;
  }

  section {
    &:not(:last-child) {
      margin-bottom: 40px;
    }

    &#banner {
      aspect-ratio: 40 / 10;
      height: auto;
      width: 100%;

      @include little {
        aspect-ratio: 16/10;
      }

      .banner-container {
        width: 100%;
        overflow: hidden;
        height: 100%;
        border-radius: 6px;
        background-color: #f2f2f2;
        position: relative;

        @include small {
          border-radius: 0;
        }

        img {
          width: 100%;
          z-index: 0;
          position: absolute;
          object-fit: cover;
          height: 100%;
          max-height: 100%;
        }

        .img_mob {
          display:none;

          @include little {
            display:block;
          }
        }

        .img_pc {
          @include little {
            display:none;
          }
        }

        .text-container {
          position: absolute;
          z-index: 100;
          left: 50%;
          top: 50%;
          width: 70%;
          transform: translate(-50%, -50%);
          color: $tdx-white;
          text-shadow: 5px 2px 5px $tdx-black;
          @include little {
            top: 50%;
            width: 80%;
          }

          &.custom {
            color: #000;
            text-shadow: unset;
          }

          .line {
            width: 300px;
            height: 3px;
            background-color: $tdx-red;
            margin: 0 auto 50px;

            @include small {
              margin-bottom: 20px;
            }
            @include little {
              margin-bottom: 10px;
              width: 230px;
            }
          }

          .subtitle-2 {
            text-align: center;
            font-size: 18px !important;
            line-height: normal !important;

              @include  little {
                font-size: 14px !important;
                font-style: normal;
                font-weight: normal !important;
              }

            &.end {
              text-align: end !important;
              margin-top: 20px;
              margin-bottom: 50px;

              @include small {
                margin-bottom: 30px;
              }

               @include  little {
                 font-size: 14px !important;
                 font-style: normal;
                 font-weight: normal !important;
                 margin-top: 10px;
                 margin-bottom: 10px;
              }
            }
          }
        }
      }
    }

    &#news {

      @include small{
        width: 95%;
        margin: 0 auto;
      }

      .tdx-arrow-link {

        @include little {
          text-align: center;
          justify-content: center;
        }
      }
      .news-container {
        display: flex;
        justify-content: space-between;
        flex-flow: row wrap;
        margin-top: 24px;

        &:not(:last-child) {
          @include small {
            margin-bottom: 15px;
          }
        }
      }
    }

    &#discounts {
      .swiper {
        margin-top: 24px;
        padding-bottom: 30px;

        .swiper-pagination {
          bottom: 0;

          .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
            background: $tdx-black-alpha-12;
            opacity: 1;

            &-active {
              background: $tdx-black-alpha-87;
            }
          }
        }
      }

      .news-container {
        display: flex;
        justify-content: space-between;
        flex-flow: row wrap;
        margin-top: 24px;
      }
    }
  }
}
