@import "../../variables";
@import "../../mixins";

.move-to-another-order-form {
  .inputs-container {
    display: block !important;
    text-align: center;
    margin-bottom: 20px;

    .label {
      margin-bottom: 10px;
    }
  }

  .tdx-table-container {
    min-width: 1050px;
  }
}

.create-new-order {
  .inputs-container {
    margin-bottom: 20px;

    .label {
      margin-bottom: 10px;
    }
  }
}

.delete-items {
  .inputs-container {
    margin-bottom: 20px;
  }
}

.move-to-shipment {
  .tdx-outlined-input.select {
    width: 200px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}
