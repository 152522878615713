@import "../../variables";
@import "../../mixins";

.tdx-shipment-page {
  padding: 24px 0 60px 0;

  .MuiBreadcrumbs-root {
    margin-bottom: 10px;

    a {
      color: $tdx-black-alpha-60;
    }
  }

  .title-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .tdx-button {
      min-width: 160px;
      height: 30px;
      font-size: 13px !important;
    }
  }

  .status-block {
    display: flex;
    padding: 8px 10px;
    margin-bottom: 16px;
    background: $tdx-black-alpha-4;
    border-radius: 4px;

    .status-item {
      margin-right: 70px;

      .subtitle-2 {
        font-weight: 400 !important;
        color: $tdx-black-alpha-60;
      }

      h6 {
        color: $tdx-black-alpha-87;
      }
    }
  }
}
