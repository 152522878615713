@import "../variables";
@import "../mixins";

.tdx-input {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;

  @include small {
    gap: 5px;
  }

  label {
    position: relative;
    margin: 0;
    width: 25%;
    transform: unset;

    @include small {
      width: 35%;
    }
  }

  .MuiOutlinedInput-root {
    margin:0;
    width: 75%;

    .MuiOutlinedInput-notchedOutline {
      top:0;

      legend {
        display:none;
      }
    }
  }
}

.tdx-outlined-input {
  @include tdx-outlined-input-border;

  .MuiOutlinedInput-root {
    color: $tdx-black-alpha-87 !important;
    padding: 0;

    .MuiInputAdornment-root {
      margin: 0;

      .MuiIconButton-root {
        margin: 0;
      }
    }

    input {
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.15px;
      padding: 19.5px 10px;

      &.Mui-disabled {
        color: $tdx-black-alpha-30 !important;
        @include disabled;
      }
    }
  }

  .MuiInputLabel-root {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: $tdx-black-alpha-87 !important;
    transform: translate(10px, 8px) scale(1);

    &.MuiInputLabel-shrink {
      transform: translate(12px, -8px) scale(0.75);
    }

    &.Mui-disabled {
      color: $tdx-black-alpha-30 !important;
    }
  }

  &.integer {
    width: 92px;

    .MuiOutlinedInput-root {
      font-size: 14px;
      line-height: 20px;

      & input {
        padding: 14px 6px;
      }
    }
  }

  &.counter {
    width: 100px;
    min-width: 85px;

    .MuiOutlinedInput-root {
      font-size: 14px;
      line-height: 20px;

      & input {
        padding: 14px 0;
        text-align: center;

        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        &[type="number"] {
          -moz-appearance: textfield;
        }
      }
    }

    .MuiFormHelperText-root {
      font-size: 10px;
      line-height: 14px;
      text-align: center;
      margin: 0;
    }

    .tdx-button {
      color: $tdx-black-alpha-87;
      background: $tdx-black-alpha-4 !important;
    }
  }

  &.select {
    width: 113px;

    .MuiInputLabel-root {
      font-weight: 500;
      letter-spacing: 0;
      transform: translate(0, -18px) scale(0.75);

      &.MuiInputLabel-shrink {
        transform: translate(0, -18px) scale(0.75);
      }
    }

    .MuiSelect-select {
      padding: 3px 24px 3px 10px !important;
      min-height: 0;
      height: auto;

      .placeholder {
        color: $tdx-black-alpha-30;
      }

      &.Mui-disabled {
        @include disabled;
      }
    }

    .MuiOutlinedInput-root {
      font-size: 14px;
      line-height: 24px;

      input {
        padding: 0;
        margin: 0;
        border: 0;
      }

      legend {
        max-width: 0;
      }
    }

    .MuiSvgIcon-root {
      transition: transform 0.15s linear;
      right: 0;
      color: inherit;
    }

    .Mui-disabled .MuiSvgIcon-root {
      color: $tdx-black-alpha-30 !important;
    }
  }
}

.tdx-radio-group {
  .MuiFormLabel-root {
    font-size: 16px;
    line-height: 150%;
    color: $tdx-black-alpha-87 !important;
    font-weight: 500;
    letter-spacing: 0;

    &.Mui-disabled {
      color: $tdx-black-alpha-30 !important;
    }
  }

  .MuiFormGroup-root {
    gap: 20px;
  }
}

.tdx-selector-menu {
  max-height: 300px !important;
  margin-top: 4px;
  border: 1px solid #e0e0e3;

  .tdx-select-item {
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 24px !important;
    color: $tdx-black-alpha-87 !important;
    padding: 3px 9px !important;

    @include hover {
      background-color: $tdx-black-alpha-4;
    }

    &.Mui-selected {
      background-color: $tdx-black-alpha-12 !important;
    }
  }
}

.tdx-switch {
  &.MuiSwitch-root {
    width: auto;
    height: auto;
    padding: 6px;
  }

  .MuiSwitch-switchBase {
    color: #262424;
    padding: 5px;
    top: 50%;
    transform: translate(0, -50%);

    &:hover {
      background: $tdx-black-alpha-12 !important;
    }

    .MuiSwitch-thumb {
      width: 18px;
      height: 18px;
    }

    &.Mui-checked {
      transform: translate(15px, -50%);
      color: $tdx-red;
    }

    &.Mui-checked + .MuiSwitch-track {
      background-color: $tdx-red-alpha-30;
    }

    &.Mui-disabled {
      @include disabled;

      &:hover {
        background: none !important;
      }

      & + .MuiSwitch-track {
        opacity: 0.65;
      }
    }
  }

  .MuiSwitch-track {
    width: 32px;
    height: 16px;
    border-radius: 22px;
    background-color: $tdx-black-alpha-12;
    opacity: 1;
  }

  & + .MuiTypography-root {
    color: $tdx-black-alpha-87 !important;
  }
}

.tdx-checkbox {
  &.MuiCheckbox-root {
    padding: 0;

    &:hover {
      color: $tdx-black-alpha-87;
    }

    &.Mui-checked {
      color: $tdx-black-alpha-87;
    }

    &.Mui-disabled {
      @include disabled;
      color: rgba(38, 36, 36, 0.12);
    }

    & + .MuiTypography-root {
      margin-left: 6px;
      word-break: break-word;

      @include min {
        font-size: 12px;
      }

      .shadow-label {
        color: $tdx-black-alpha-60;
        text-wrap: nowrap;
      }
    }

    &.Mui-disabled + .MuiTypography-root {
      color: $tdx-black-alpha-30;
      word-break: break-word;

      .shadow-label {
        font-weight: 500 !important;
        color: currentColor;
      }
    }
  }

  &.MuiRadio-root {
    padding: 0;

    &:hover {
      color: $tdx-black-alpha-87;
    }

    &.Mui-checked {
      color: $tdx-black-alpha-87;
    }

    &.Mui-disabled {
      @include disabled;
      color: rgba(38, 36, 36, 0.12);
    }

    & + .MuiTypography-root {
      margin-left: 4px;

      @include min {
        font-size: 12px;
      }

      .shadow-label {
        color: $tdx-black-alpha-60;
        text-wrap: nowrap;
      }
    }

    &.Mui-disabled + .MuiTypography-root {
      color: $tdx-black-alpha-30;

      .shadow-label {
        font-weight: 500 !important;
        color: currentColor;
      }
    }
  }
}

.tdx-date-picker-day {
  &.Mui-selected {
    background-color: $tdx-red !important;
  }

}
.tdx-search-input-container{

  @include small {
    width: 100%
  }

}
.tdx-search-input {
  height: 32px;
  width: 440px;
  padding: 0 10px;
  background: $tdx-black-alpha-4;
  font-weight: 400 !important;
  border-radius: 6px;

  @include min {
    width: 300px
  }

  @include small {
    width: 100%
  }

  &.Mui-focused {
    background: $tdx-black-alpha-12;
  }

  & input {
    padding: 0;

    @include small {
      width: 100%;
    }

    @include little {
      font-size:16px;
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.54);
      opacity: 1;
    }
  }

  &:before,
  &:after {
    content: none !important;
  }

}

.tdx-search-input-paper {
  margin-top: 8px;

  .paper-container {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 64px;
    max-height: 450px;
    min-width: 440px;
    max-width: 1120px;
    border-radius: 10px;
    overflow: hidden auto;
    width:100%;

    @include small {
      min-width: 95vw;
      max-width: 95vw;
      width: 95vw;
    }

    @include small {
      min-width: 95vw;
      max-width: 95vw;
      width: 95vw;
    }

    .center {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .paper-search-item {
      display: flex;
      align-items: center;
      width: 100%;
      flex: 0 0 auto;
      padding-left: 22px;
      padding-right: 18px;
      border-bottom: 1px solid $tdx-gray;
      justify-content: center;

      @include small {
        width: 100%;
        height: 100%;
        padding: 6px 10px;
        gap: 5px;
      }

      &.category {
        height: 40px;
        justify-content: flex-start;
      }

      &.filter {
        height: 40px;
      }

      &.product {
        min-height: 64px;

        @include little {
          flex-direction: column;
        }

        .search-product-content {
          display: flex;
          align-items: center;
          gap: 10px;
          width: calc(100% - 500px);

          @include small {
            display:block;
            width: 40%;
            max-width: 320px;
          }

          @include little {
            display: grid;
            max-width: 100%;
            width: 100%;
            gap: 10px;
            grid-template-columns: 1fr 1fr;
          }

          img {
            width: 56px;
            min-height: 56px;
            margin-right: 10px;
            aspect-ratio: 1/1;
            object-fit: contain;

            @include little {
              width:80%;
              margin-right: 0;
              grid-row: 1/3;
            }
          }

          a {
            width: 300px;
            margin-right: 10px;
            color: $tdx-black-alpha-87;
            text-decoration: none;
            word-break: break-word;

            @include hover {
              text-decoration: underline;
            }

            @include little {
              width: 100%;
              margin-right: 0;
            }

          }
          h6 {
            @include little {
              font-size: 14px;
              line-height: 18px;
            }
          }

          .price {
            width: 100px;
            margin-right: 10px;
          }

          .code {
            width: 150px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-right: 10px;
          }

          .ext_id {
            min-width: 175px;
            text-align: center;
          }
        }

        .offers {
          display: flex;
          width: 500px;

          @include small {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;

            .buttons-wrapper {
              margin-right: 0;

              .tdx-button.counter{
                height: 100%;
              }

              .tdx-outlined-input{
                &.counter {
                  width:85px;
                }
              }
            }
          }

          @include little {
            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 4;
          }

          .tdx-button.large {
            padding: 5px 0;
            width: 100%;
            min-width: 200px;
            height: 55px;
            min-height:55px;
            flex: 1 1;
            gap: 4px;

            &:not(:last-child) {
              margin-right: 5px;
            }

            .MuiButton-startIcon {
              @include small {
                margin-right:0;
              }
            }

            &.counter {
              display: flex;
              align-items: center;
              justify-content: center;

              .MuiOutlinedInput-root {
                font-size: 14px;
                line-height: 20px;

                & input {
                  padding: 14px 0;
                  text-align: center;

                  &::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                  }

                  &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                  }

                  &[type="number"] {
                    -moz-appearance: textfield;
                  }
                }
              }

              .MuiFormHelperText-root {
                font-size: 10px;
                line-height: 14px;
                text-align: center;
                margin: 0;
              }

              .tdx-button {
                color: $tdx-black-alpha-87;
                background: $tdx-black-alpha-4 !important;
              }

              .icon-button {
                padding: 4px;
                gap: 5px;
                background: unset !important;

                .label {
                  color: $tdx-black;

                  @include little {
                    display:none;
                  }
                }

                svg {
                  width: 0.8em;
                  height: 0.8em;
                }
              }
              .clear-icon {
                  color: #d91f2a;
              }
              .description-icon {
                  color: #4caf50;
              }
            }

            @include small {
              min-width:unset;
              padding: 5px;
            }
          }

          .buttons-wrapper {
            & > button {
              padding: 20px 5px;
              width: 170px;

              @include small {
                height: 100%;
              }
            }
          }

          a {
            width: 300px;
            margin-right: 10px;
            color: $tdx-black-alpha-87;
            text-decoration: none;
            word-break: break-word;

            @include hover {
              text-decoration: underline;
            }

            @include little {
              width: 100%;
              margin-right: 0;
            }

          }
          h6 {
            @include little {
              font-size: 14px;
              line-height: 18px;
            }
          }

          .price {
            width: 100px;
            margin-right: 10px;
          }

          .code {
            width: 150px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-right: 10px;
          }
        }

        .offers {
          @include small {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;

            .buttons-wrapper {
              margin-right: 0;

              .tdx-button.counter{
                height: 100%;
              }

              .tdx-outlined-input{
                &.counter {
                  width:85px;
                }
              }
            }
          }
        }

        b.green {
          color: $tdx-green;
        }
      }
    }

    .paper-search-group {
      display: flex;
      border-bottom: 1px solid #e0e0e3;
      flex-wrap: wrap;
      justify-content: space-between;

      .paper-search-item {
        width:50%;
        margin-bottom: -1px;

        @include small {
          width: 100%;
          height: 100%;
          padding: 6px 10px;
        }
      }
    }

    .paper-search-category-link {
      color: $tdx-black;
      margin-left: 5px;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
  .more-variants {
    margin-left: 66px;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.tdx-range-slider-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  width: 75%;

  @include little {
    width: 65%;
  }

  .inputs-container {
    display: flex;
    width: 100%;

    .range-input {
      display: flex;
      flex-flow: column nowrap;
      width: 100%;

      &:first-child {
        .tdx-outlined-input.integer {
          .MuiOutlinedInput-notchedOutline {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }

      &:last-child {
        margin-left: -1px;

        & > span.body-2 {
          margin-left: 1px;
        }

        .tdx-outlined-input.integer {
          .MuiOutlinedInput-notchedOutline {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }

      & > span.body-2 {
        color: $tdx-black-alpha-60;
      }

      .tdx-outlined-input.integer {
        width: 100%;

        .MuiOutlinedInput-notchedOutline {
          @include hover {
            border-color: rgba(0, 0, 0, 0.3);
          }
        }

        .MuiOutlinedInput-root input {
          font-size: 14px;
          padding: 15px 6px;
        }
      }
    }
  }

  .tdx-range-slider {
    width: 96%;
    height: 4px;
    padding: 0;
    margin: 0 2%;
    border-radius: 1px;

    .MuiSlider-rail {
      opacity: 1;
      background-color: $tdx-red;
    }

    .MuiSlider-track {
      color: #11a543;
      border: 0;
    }

    .tdx-range-slider-thumb {
      position: absolute;
      display: flex;
      margin-top: 2px;
      margin-left: -5px;
      pointer-events: auto !important;
      cursor: pointer;

      input {
        margin: 0 !important;
      }
    }
  }
}
.filter {
  .tdx-filter-dictionary {
    display: flex;
    flex-direction: column;

    @include little {
      width: 100%;
    }

    & label {
      width: 100%;
    }

    .tdx-checkbox {
      margin-right: 2px;
    }

    .tdx-button {
      margin-top: 10px;

      .bg-gray .select {

        @include little {
          width:  100%
        }
      }
    }

  }
}

.tdx-filter-text {
  .tdx-input {
    label {
      width: unset;
      position: absolute;
      font-size: 14px;
      transform: translate(10px, 10px) scale(1);
    }

    .MuiOutlinedInput-root {
      width:100%;

      legend {
        display: block;
        height: 0;
        font-size: 0.75em;
      }
    }
  }
}

.tdx-dictionary-paper {
  margin-left: 10px;

  @include little{
    margin: 10px 5px;
  }

  .MuiFormControl-root {
    display: block;
    max-height: 500px;
    padding: 20px 0 20px 20px;
    overflow-x: hidden;
    overflow-y: auto;

    .columns-wrapper {
      column-width: 150px;
      column-count: 1;
      column-gap: 0;

      &.column-2 {
        width: 360px;
        column-count: 2;

        @include  little {
          width: 340px
        }
      }

      &.column-3 {
        width: 540px;
        column-count: 3;
      }

      &.column-4 {
        width: 680px;
        column-count: 4;
      }
    }

    .dictionary-option {
      width: 160px;

      .MuiFormControlLabel-root {
        width: 100%;
        padding-right: 10px;
        align-items: stretch;

        .MuiCheckbox-root {
          display: flex;
          align-items: flex-start;

          .MuiSvgIcon-root {
            width: 20px;
            height: 20px;
          }

          & + .MuiTypography-root {
            display: flex;
            align-items: center;
            font-size: 12px;
            word-break: break-word;
          }
        }
      }
    }
  }
}

.tdx-filter-range {
  display: flex;
  flex-direction: column;

  .tdx-checkbox {
    margin-right: 2px;
  }

  .inputs-container {
    display: flex;
    margin-top: 10px;

    .tdx-outlined-input.integer,
    .tdx-outlined-input.select {
      width: 100px;

      &:first-child {
        .MuiOutlinedInput-notchedOutline {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      &:last-child {
        margin-left: -1px;

        .MuiOutlinedInput-notchedOutline {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      .MuiOutlinedInput-root input {
        font-size: 14px;
        padding: 15px 6px;
      }
    }
  }
}

.MuiAutocomplete-popper {
  .MuiPaper-root {
    margin-top: 6px;

    [role="listbox"] {
      max-height: 38vh;
    }

    .MuiAutocomplete-option[aria-selected="true"] {
      background-color: $tdx-black-alpha-12 !important;
    }
  }
}

.MuiDatePickerRange {
  .tdx-input {
    .tdx-datepicker-range-group {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 75%;

        .MuiOutlinedInput-root {
          width:100%;
          padding-top: 3px;
          padding-bottom: 3px;
        }
    }

    label {
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100% - 24px);
      z-index: 1;
      pointer-events: none;
    }
  }
}

.tdx-filter-range {
  .tdx-datepicker-range-group {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;

        .MuiOutlinedInput-root {
          width:100%;
          padding-top: 3px;
          padding-bottom: 3px;

          .MuiInputAdornment-root {
            margin-left: 0;
          }
        }
    }
}

.catalog-tags {
  .catalog-tags__list {
    display: flex;
    flex-wrap: wrap;
    gap: 7px;
  }
}

.hide-label {
  width: 100%;

  label {
    width: 100%;

    .MuiTypography-root {
      text-overflow: ellipsis;
      text-wrap: nowrap;
      overflow: hidden;
    }
  }
}