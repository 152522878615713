@import "../variables";
@import "../mixins";

.tdx-button {
  border-radius: $tdx-btn-border-radius;

  //.tdx-button__label {
  //  margin-top: 3px;
  //}

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__label {
    display: block;
    font-weight: 700;
    font-size: 10px;
    line-height: 100%;
    color: $tdx-black-alpha-87;
    margin-top: 2px;
  }

  &__availability, &__company {
    font-size: 10px;
    font-weight: 400;
    color: $tdx-black-alpha-60;
    line-height: 1.1;
  }

  &__counter {
    background: $tdx-green;
    color: #fff;
    position: absolute;
    right: 0.4em;
    top: 0.4em;
    text-align: center;
    border-radius: 50%;
    width: 1.4em;
    font-size: 0.8em;
    aspect-ratio: 1 / 1;
}

  &.loading {
    @include disabled;
  }

  &:disabled {
    background: $tdx-black-alpha-12 !important;
    color: $tdx-black-alpha-30 !important;
    @include disabled;

    &.border {
      border: 1px solid $tdx-black-alpha-12 !important;
    }

    .tdx-contained-button__label,
    .MuiSvgIcon-root {
      color: currentColor;
    }
  }

  &.large {
    height: 42px;

    &.bg-red {
      min-width: 148px;
      color: $tdx-red;
      background: $tdx-red-alpha-8;

      @include hover {
        background: $tdx-red-alpha-30;
      }
    }

    &.bg-gray {
      min-width: 120px;
      color: $tdx-black-alpha-87;
      background: $tdx-black-alpha-4;

      @include hover {
        background: $tdx-black-alpha-12;
      }
    }

    &.bg-transparent {
      min-width: 230px;
      color: $tdx-black-alpha-87;
      background: none !important;

      @include hover {
        color: $tdx-red;
      }
    }

    &.bg-blue {
      color: $tdx-blue;
      background: $tdx-blue-alpha-10;

      @include hover {
        background: rgba($tdx-blue, 0.4);
      }
    }

    &.bg-yellow-light {
      color: $tdx-green;
      background: $tdx-yellow-light-alpha-10;

      @include hover {
        background: rgba($tdx-yellow-light, 0.4);
      }
    }

    &.bg-green {
      color: $tdx-green;
      background: $tdx-green-alpha-10;

      @include hover {
        background: rgba($tdx-green, 0.4);
      }
    }

    &.bg-black {
      min-width: 230px;
      color: #fff;
      background: $tdx-black-alpha-87;

      @include hover {
        background: $tdx-black-alpha-75;
      }

      &.invert {
        background: $tdx-black-alpha-75;

        @include hover {
          background: $tdx-black-alpha-87;
        }
      }
    }
  }

  &.medium {
    height: 36px;

    &.bg-red {
      min-width: 196px;
      color: #fff;
      background: $tdx-red;

      @include hover {
        background: $tdx-burgundy;
      }

      &.invert {
        color: $tdx-red;
        background: $tdx-red-alpha-10;

        @include hover {
          background: $tdx-red-alpha-30;
        }
      }

      &.opacity {
        min-width: 90px;
        color: $tdx-red;
        background: $tdx-red-alpha-8;

        @include hover {
          background: $tdx-red-alpha-30;
        }

        &.border {
          border: 1px solid $tdx-red-alpha-50;
        }
      }
    }

    &.bg-black {
      min-width: 212px;
      color: #fff;
      background: $tdx-black-alpha-87;

      @include hover {
        background: $tdx-black-alpha-75;
      }

      &.invert {
        background: $tdx-black-alpha-75;

        @include hover {
          background: $tdx-black-alpha-87;
        }
      }
    }

    &.bg-green {
      min-width: 196px;
      color: #fff;
      background: $tdx-green;

      @include hover {
        background: $tdx-green-dark;
      }

      &.invert {
        color: $tdx-green;
        background: $tdx-green-alpha-10;

        @include hover {
          background: $tdx-green-alpha-30;
        }

        &.is-active {
          background: $tdx-green-alpha-30;
        }
      }
    }

    &.bg-gray {
      background: $tdx-black-alpha-4;
      color: $tdx-black-alpha-87;

      @include hover {
        background: $tdx-black-alpha-12;
      }
    }

    &.bg-gray-dark {
      background: $tdx-black-alpha-4;
      color: $tdx-black-alpha-87;

      @include hover {
        color: $tdx-white;
        background: $tdx-black-alpha-87;
      }

      &.selected {
        color: $tdx-white;
        background: $tdx-black-alpha-87;
      }
    }

    &.bg-blue {
      color: #fff;
      background: $tdx-blue;

      @include hover {
        background: $tdx-blue-dark;
      }

      &.invert {
        color: $tdx-blue;
        background: $tdx-blue-alpha-10;

        @include hover {
          background: $tdx-blue-alpha-30;
        }
      }
    }

    &.bg-transparent {
      min-width: 143px;
      color: $tdx-black-alpha-87;
      background: none !important;

      @include hover {
        background: $tdx-black-alpha-4;
      }

      &.is-active {
        background: $tdx-black-alpha-4 !important;
      }

      &.border {
        border: 1px solid $tdx-black-alpha-87;

        @include small {
          padding: 5px;
        }
      }
    }
  }

  &.small {
    height: 30px;

    &.bg-red {
      min-width: 147px;
      color: #fff;
      background: $tdx-red;

      @include hover {
        background: $tdx-burgundy;
      }
    }

    &.bg-green {
      color: #fff;
      background: $tdx-green;

      @include hover {
        background: $tdx-green-dark;
      }
    }

    &.bg-gray {
      background: $tdx-black-alpha-4;
      color: $tdx-black-alpha-87;

      @include hover {
        background: $tdx-black-alpha-12;
      }

      &.icon {
        min-width: auto;
        width: 28px;
        height: 28px;
        padding: 8px;
      }

      &.select {
        min-width: 280px;
        justify-content: space-between;

        @include min {
          min-width: 120px;
        }

        @include small {
          max-width: 240px;
          min-width: 200px;
        }

        @include little {
          min-width: 100%;
        }
      }
    }

    &.bg-black {
      min-width: 147px;
      color: #fff;
      background: $tdx-black-alpha-87;

      @include hover {
        background: $tdx-black-alpha-75;
      }

      &.invert {
        background: $tdx-black-alpha-75;

        @include hover {
          background: $tdx-black-alpha-87;
        }
      }
    }

    &.bg-transparent {
      min-width: 143px;
      color: $tdx-black-alpha-87;
      background: none !important;

      @include hover {
        background: $tdx-black-alpha-4;
      }

      &.border {
        border: 1px solid $tdx-black-alpha-87;
      }
    }
  }

  .MuiButton-startIcon {
    margin-left: 0;
    margin-right: 8px;
  }
}

.tdx-pagination {
  .MuiPagination-ul {
    li {
      &:not(:last-child) {
        margin-right: 10px;
      }

      .MuiPaginationItem-root {
        width: 36px;
        height: 36px;
        margin: 0;
        padding: 0;
        font-size: 12px !important;
        font-weight: 400 !important;
        color: $tdx-black-alpha-87;
        letter-spacing: 0.4px;
        border-radius: 50%;

        &:hover {
          background-color: $tdx-black-alpha-12;
        }

        &.Mui-selected {
          background-color: $tdx-black-alpha-4;
        }

        &.MuiPaginationItem-ellipsis {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.tdx-chip {
  border-radius: $tdx-btn-border-radius !important;
  height: 28px !important;
  font-size: 14px !important;
  font-weight: 400 !important;

  @include min {
    font-size: 12px !important;
  }

  @include medium {
    font-size: 12px !important;
  }

  &.bg-green {
    color: $tdx-green;
    background-color: $tdx-green-alpha-10;
  }

  &.bg-blue {
    color: $tdx-blue;
    background-color: $tdx-blue-alpha-10;
  }

  &.bg-yellow-light {
    color: $tdx-yellow-light;
    background-color: $tdx-yellow-light-alpha-10;
  }

  &.bg-red {
    color: $tdx-red;
    background-color: $tdx-red-alpha-10;
  }

  &.bg-gray {
    color: $tdx-gray-light-text;
    background-color: $tdx-gray-light-text-alpha;
  }

  &.bg-green-dark {
    color: #fff;
    background-color: $tdx-green-verydark;
  }

  &.bg-transparent {
    background-color: unset;
  }
}

.sticky-button-wrapper {
  position: sticky;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.tdx-compare-list-button {
  .button-block {
    background-color: $tdx-white;
    border: 1px solid $tdx-red-alpha-50;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    display: flex;
    justify-content: space-between;

    a {
      text-decoration: none;
      color: $tdx-red;
      font-weight: 500;
    }

    .button {
      padding: 10px 10px 7px;
      cursor: pointer;
      color: $tdx-red;
      font-weight: 400;
      background-color: $tdx-red-alpha-10;

      &:first-child {
        border-right: 1px solid $tdx-red-alpha-50;
      }
    }
  }
}

.tdx-buffer-list-button {
  .button-block {
    background-color: $tdx-white;
    border: 1px solid $tdx-blue-alpha-30;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    display: flex;
    justify-content: space-between;

    a {
      text-decoration: none;
      color: $tdx-blue;
      font-weight: 500;
    }

    .button {
      padding: 10px 10px 7px;
      cursor: pointer;
      color: $tdx-blue;
      font-weight: 400;
      background-color: $tdx-blue-alpha-10;

      &:first-child {
        border-right: 1px solid $tdx-blue-alpha-30;
      }
    }
  }
}

.tdx-sort-button {
  padding: 3px !important;
  margin-left: 5px !important;
  color: $tdx-black-alpha-60;
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  visibility: hidden;

  &.none-type {
    color: $tdx-black-alpha-30;
  }

  &.rotate {
    transform: rotate(-180deg);
  }

  &.active {
    visibility: visible;
  }
}

.buttons-wrapper {
  flex: 1 1;

  &.hover {
    .tdx-button {
      &.hover {
        display:none;
      }
    }

    &:hover {
      .tdx-button {
        display:none;

        @include little {
          display:flex;
        }

        &.hover {
          display:flex;

          @include little {
            display:none;
          }
        }
      }
    }

    @include little {
      &.is-active {
        .tdx-button {
          display:none;

          &.hover {
            display:flex;
          }
        }
      }
    }

    .wait-list-button {
      font-size: 13px !important;
    }
  }

  .tdx-button {
    width:100%;
    margin-right: 0 !important;

    @include little {
      padding: 5px;
      min-width: 130px !important;
    }
  }

  &:not(:last-child){
    margin-right:5px;
  }

  .icon {
    &.icon-red {
      color: $tdx-red-alpha-70 !important;
    }
  }
}

.tdx-dropdown {
  padding: 0 !important;
  height: auto !important;
  justify-content: flex-start !important;
  min-width: unset !important;

  .tdx-button__content {
    display: flex;
    flex-direction: row;
    font-size: 10px;
    font-weight: 700;
    align-items: center;
    gap: 4px;

    .arrow-dropdown-icon {
      cursor:pointer;
      transition:300ms;

      &.active {
        transform: rotate(-180deg)
      }
    }
  }
}

.tdx-button-copy {
  padding: 0 !important;
  font: inherit !important;

  .MuiButton-endIcon {
    margin-right: unset;
    margin-left: 4px;
    display: inline-block;
  }

  .MuiTouchRipple-root {
    display:none;
  }

  svg {
    font-size: 0.8em !important;
  }

  &:hover {
    background: none !important;
  }

  .label {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.MuiIconButton-root {
  &.tdx-button {
    border-radius:4px;
  }
}

.MuiDialog-root {
  .MuiDialog-paperWidthXs {
    .MuiDialogActions-root {
      justify-content: center;
    }
  }

  .MuiDialogContentText-root {
    color: rgba(0, 0, 0, 0.87);
  }

  .tdx-button {
    &.medium {
      @include little {
        min-width: unset;
      }
    }
  }
}