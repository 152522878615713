@import "variables";

// General
@mixin hover {
  &:not([disabled]):hover {
    @content;
  }
}

@mixin disabled {
  cursor: not-allowed !important;
  pointer-events: auto !important;
}

// Responsive layout
@mixin extra-small {
  @media screen and (max-width: 425px) {
    @content;
  }
}
@mixin little {
  @media screen and (max-width: 768px) {
    @content;
  }
}
@mixin small {
  @media screen and  (max-width: 1024px) {
    @content;
  }
}

@mixin x-min {
  @media screen and  (max-width: 1280px) {
    @content;
  }
}

@mixin min {
  @media screen and (min-width: 1024px) and (max-width: 1280px) {
    @content;
  }
}

@mixin medium {
  @media screen and (min-width: 1281px) and (max-width: 1600px) {
    @content;
  }
}

@mixin max {
  @media screen and (min-width: 1601px) and (max-width: 1920px) {
    @content;
  }
}

// UI-Kit
@mixin tdx-outlined-input-border {
  .MuiOutlinedInput-notchedOutline {
    border-width: 1px !important;
    border-style: solid;
    border-color: $tdx-black-alpha-30;
    border-radius: $tdx-btn-border-radius;

    @include hover {
      border-color: $tdx-black-alpha-87 !important;
    }

    legend {
      font-size: 0.85em;
    }
  }

  & .Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: $tdx-black-alpha-87 !important;
    }
  }

  & .Mui-error {
    .MuiOutlinedInput-notchedOutline {
      border-color: $tdx-red !important;
    }
  }

  & .Mui-disabled {
    .MuiOutlinedInput-notchedOutline {
      border-color: $tdx-black-alpha-12 !important;
    }
  }
}
