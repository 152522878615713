@import "../../variables";
@import "../../mixins";

.tdx-news-page {
  padding: 24px 0 60px 0;

  @include little {
    padding: 15px 0 20px 0;
  }
  &.wrapper {

    @include small {
      width: 90%;
    }
  }
  .MuiBreadcrumbs-root {
    margin-bottom: 24px;

    a,
    p {
      color: $tdx-black-alpha-60;
    }
  }

  #news {
    width: 100%;
    overflow: hidden;

    & > p {
      font-size: 16px;
      line-height: 22px;
      font-weight: 500;
      color: $tdx-black-alpha-87;
      margin: 24px 0;

      @include min {
        font-size: 14px;
      }
      @include small {
        font-size: 14px;
      }

    }

    img {
      display: block;
      max-width: 100%;
    }

    ul {
      margin: 0;
      padding: 0 0 0 1em;

      li {
        font-size: 15px;
        line-height: 18px;
        font-weight: 500;
        color: $tdx-black-alpha-87;

        &:not(:last-child) {
          margin-bottom: 24px;
        }

        &::marker {
          display: block;
        }
      }
    }
  }

  .news-switching {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn {
      color: $tdx-black-alpha-87;

      &.hidden {
        visibility: hidden;
      }

      &:first-child {
        .MuiIconButton-root {
          margin-right: 10px;
        }
      }

      &:last-child {
        .MuiIconButton-root {
          margin-left: 10px;
        }
      }

      .MuiIconButton-root svg {
        font-size: 1em;
      }
    }
  }
}
