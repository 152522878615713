@import "../../variables";
@import "../../mixins";

.user-data {
  padding-top: 24px;
  padding-bottom: 60px;
  background: $tdx-white;
  color: $tdx-black-alpha-87;

  .wrapper {

    @include small {
      width: 95%;
      margin: 0 auto;
    }
  }
  .page-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include small {
      justify-content: center;
    }
  }

  .form {
    margin-top: 24px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border: 1px solid $tdx-gray;
    background: #fff;
    overflow: hidden;

    .form-header {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $tdx-gray;

      .MuiTabs-root {
        min-height: auto;

        .tab {
          width: 168px;
          min-height: auto;
          min-width: auto;
          padding: 10px;
          margin: 0;
          color: $tdx-black-alpha-60;

          &.Mui-selected {
            color: $tdx-black-alpha-87;
            font-weight: 700 !important;
          }

          &.Mui-disabled {
            @include disabled;
          }
        }

        .MuiTabs-indicator {
          background-color: $tdx-black-alpha-87;
        }
      }
    }

    .form-body {
      .container {
        @include small{
           padding: 15px 0;
        }
      }
      .height-fix {
        max-height: 650px;
        overflow: hidden auto;

        @include small {
          max-height: unset;
        }
      }
    }
  }
}
