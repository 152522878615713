@import "mixins";

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

h1 {
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;

  @include min {
    font-size: 20px;
    line-height: 26px;
  }
  @include small {
    font-size: 20px;
  }
  @include little {
    font-size: 20px;
  }
}

h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;

  @include min {
    font-size: 18px;
    line-height: 24px;
  }
  @include small {
    font-size: 18px;
    line-height: 24px;
  }

}

h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;

  @include min {
    font-size: calc(10px + 10 * (100vw / 1920));
    line-height: calc(20px + 6 * (100vw / 1920));
  }
}

h4 {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;

  @include min {
    font-size: 14px;
    line-height: calc(16px + 8 * (100vw / 1920));
  }
}

h5 {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;

  @include min {
    font-size: 14px;
    line-height: 20px;
  }
}

h6 {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;

  @include min {
    font-size: 12px;
    line-height: 18px;
  }

  @include  small {
    font-size: 16px;
  line-height: 20px;
  }
}

.body-1 {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 18px !important;


  @include min {
    font-size: 12px !important;
    line-height: 16px !important;
  }

  @include small {
    font-size: 16px !important;
    line-height: 20px !important;
  }

  @include little {
    font-size: 14px !important;
    line-height: 18px !important;
  }

}

.body-2 {
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 17px !important;

  @include min {
    font-size: 11px !important;
    line-height: 14px !important;
  }
}


.subtitle-1 {
  font-weight: 700 !important;
  font-size: 11px !important;
  line-height: 15px !important;

  @include min {
    font-size: 9px !important;
    line-height: 14px !important;
  }
}

.subtitle-2, .subtitle-2 .MuiTypography-root {
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 14px !important;

  @include min {
    font-size: 9px !important;
    line-height: 13px !important;
  }
}

button {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px !important;

  @include min {
    font-size: 12px !important;
    line-height: 16px !important;
  }

  .small {
    font-size: 13px !important;
    line-height: 15px !important;

    @include min {
      font-size: calc(10px + 3 * (100vw / 1920)) !important;
      line-height: calc(10px + 5 * (100vw / 1920)) !important;
    }
  }
}

a {
  color: $tdx-black-alpha-87;

   &:hover, &:active {
     color: $tdx-black-alpha-87;
   }
}



