@import "../../variables";
@import "../../mixins";

.tdx-product-page {

  @include small {
    max-width: 95%;
    margin: auto;
  }

  #product-info {
    padding: 15px 0;

    .MuiBreadcrumbs-root {
      margin-bottom: 25px;

      a,
      p {
        color: $tdx-black-alpha-60;
      }
    }

    .info-container {
      display: flex;

      @include little {
        flex-direction: column;

      }
      .productName {
        margin-bottom: 5px;

        h1 {
          display: contents;

          &:after {
            content: '';
            width: 15px;
            display: inline-block;
          }
        }

        .productExtId {
          color: #6A6A6A;
          display: inline-block;
        }
      }
      .productName-for-mobile {
        display: none;

        .productName {
          display:flex;
          flex-direction: column;
        }

        @include little{
          display: block;
          text-align: center;
        }
      }
      .productSku {
        color: #6A6A6A;
        font-weight: inherit;

        &>* {
          color: inherit;
          font-weight: inherit;
        }
      }
      .productImgGap {
        @include  small {
          display: flex;
          flex-direction: column;
          row-gap: 10%;
        }

        .left-bar-img {
          @include  small {
          display: flex;
          gap: 1%;
        }
        }
      }
      .image-gallery {
        flex-shrink: 0;
        min-height: 380px;

        @include little{
          height: 100%;
          margin-top: 20px;
        }

        .image-gallery-content {
          @include little {
            display: flex;
            flex-direction: column;
          }

          &.fullscreen {
            display: flex;

            .image-gallery-image {
              height: 100vh;
            }

            .image-gallery-slide-wrapper {
              width: calc(100% - 66px);

             .image-gallery-right-nav,
              .image-gallery-left-nav {
                .image-gallery-svg {
                  width: 70px;
                  height: 70px;
                }
              }
            }
          }

          .image-gallery-thumbnails-wrapper {
            &.image-gallery-thumbnails-left {
              width: 66px;
              margin: 0 24px 0 0;

              @include little {
                width: 100%;
              }
            }

            &.image-gallery-thumbnails-bottom {
              width: 380px;

              @include little {
                width: 100%;
              }
            }

            .image-gallery-thumbnails {

              @include little {
                position: relative;
                display: flex;
              }

              .image-gallery-thumbnails-container {
                @include little {
                  border: 1px solid #F5F5F5;
                  background: #FFF;
                  box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.05);
                  width: 100%;
                  display: flex;
                  height: 100%;
                }
              }
            }

            .image-gallery-thumbnail {
              max-width: 66px;
              width: 100%;
              padding: 1px;
              margin: 0 0 20px;
              border: 2px solid transparent;
              transition: all 0.15s linear;
              cursor: pointer;

              @include little {
                max-width: 60px;
                margin: 0;
              }

              &:last-child {
                margin: 0;
              }

              &:hover,
              &.active {
                border: 2px solid $tdx-red;
              }

              img {
                object-fit: contain;
              }
            }
          }

          .image-gallery-slide-wrapper {
            width: 380px;

            @include little {
              width: 100%;
            }

            .image-gallery-left-nav,
            .image-gallery-right-nav {
              padding: 0;

              &:hover {
                color: $tdx-red;
              }

              .image-gallery-svg {
                width: 20px;
                height: 30px;
              }
            }

            .image-gallery-fullscreen-button {
              &:hover {
                color: $tdx-red;
              }
            }

            .image-gallery-bullet {
              padding: 3px;

              @include little {
                display: none;
              }

              &:hover {
                background: $tdx-red;
                border: 1px solid $tdx-red;
              }
            }
          }
        }
      }

      .img-container {
        max-width: 100%;
        width: 470px;
        height: 380px;

        .left-bar-img {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-right: 24px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .info {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 30px;

        @include small{
          margin-left: 10px;
        }

        @include little{
          margin-left: 0;
        }

        .productName {
          @include little {
            display: none;
          }
        }

        h5,  h4 {
          font-weight: inherit;

          @include little{
            font-weight: normal;
            font-size: 14px;
          }
        }

        .actions {
          display: flex;
          margin: 10px 0;

          @include little {
            justify-content: center;
          }

          & button {
            @include little {
              background-color: $tdx-black-alpha-87 !important;
              color: #fff;
            }
          }
        }

        .general-info {

          @include little {
            display: none;
          }

          table {
            display: flex;

            tr {
              display: flex;
              margin-bottom: 10px;

              .name {
                width: 300px;
                color: $tdx-black-alpha-30;
                white-space: nowrap;
                overflow: hidden;

                @include small {
                  width: 220px;
                }
              }

              .value {
                max-width: calc(100% - 300px);
              }
            }
          }

          p.body-1 {
            color: $tdx-black-alpha-87;
            cursor: pointer;

            @include hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  #product-offers {
    padding: 40px 0;
    background: $tdx-gray;

    @include little {
      padding: 10px 0;
      border-radius: 7px;
    }

    .offers {
      display: flex;
      flex-direction: column;
      row-gap: 30px;

      @include small {
        row-gap: 20px;
      }

      @include little {
        width: 95%;
        margin: 0 auto;
      }

      &-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        @include small {
          margin: 0 10px;
        }

        @include little {
          gap: 10px;
        }

        @include small {
          margin: 0 10px;
        }

        .tdx-outlined-input.select {
          width: auto;
        }
      }

      &-title {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        align-items: center;
        flex-wrap: wrap;
      }

      &-container {
        display: flex;
        flex-direction: column;
        background: #fff;
        border-radius: 10px;

        @include small {
          width: 98%;
          margin: 0 auto;
        }

        @include little {
          justify-content: center;
        }

        .row {
          height: 52px;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid $tdx-gray;

          @include little {
            height: unset;
            justify-content: space-around;
            padding: 10px;
            display: grid;
            grid-template-columns: 1fr 1fr;
          }

          .cell {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align:center;

            @include little {
              height: unset;
              align-items: flex-start;
              justify-content: flex-start;
              width: 100% !important;
            }

            svg:nth-child(2n) {
              margin-left: 10px;
            }

            &.c-0 {
              width: 205px;
              white-space: nowrap;

              @include min {
                width: 30%;
              }

              @include small {
                font-weight: bold;
                width: 50%;
                text-align: left;
                white-space: normal;
              }
            }
            &.c-1 {
              @include min {
                text-align: center;
              }

              @include small {
                text-align: center;
              }

              @include little {
                font-weight: bold;
                color: $tdx-green-font;
              }
            }

            &.c-2 {
              @include small{
                display: none;
              }
            }

            &.c-1,
            &.c-2,
            &.c-3 {
              width: 100px;
            }

            &.c-1 {
              @include min {
                width:210px;
              }
              @include small {
                width:210px;
              }
            }

            &.c-4,
            &.c-5 {
              width: 150px;

              @include min {
                text-align: center;
              }

              @include small {
                text-align: center;
              }
            }
            &.c-7,
            &.c-0 {
              flex-grow: 1;
              padding: 0 1%;

              @include  little{
                padding: 0;
              }
            }
            &.c-7,
            &.c-6 {
              @include  little{
                justify-content: end;
              }
            }
            &.c-7 {
              .tdx-button.medium.bg-black{
                @include  small {
                  min-width: 160px;
               }

                @include  little {
                  min-width: 100px;
               }
              }
            }

            @include little {
              &.c-0, &.c-1, &.c-2, &.c-3, &.c-4, &.c-5 {
                grid-column: 1;
              }
              &.c-6 {
                grid-column: 2;
                grid-row: 1/2;
              }
              &.c-7 {
                grid-column: 2;
                grid-row: 3/5;
              }
            }

            .prices {
              color: #4caf50;

              @include small {
                justify-content: left;
              }

              .old_price {
                font-size: 1em;
              }
            }
          }
        }
      }
    }
  }

  #product-specifications {
    padding: 30px 0 60px;

    h2 {
      margin-bottom: 10px;

      @include little {
        text-align: center;
      }
    }

    .specifications-container {
      padding-bottom: 20px;
      border-bottom: 1px solid $tdx-gray;

      .specification {
        padding-top: 20px;

        .items-container {
          display: flex;
          flex-wrap: wrap;
          margin-top: 10px;
          justify-content: space-between;

          @include little {
            width: 100%;
          }

          .item {
            display: flex;
            width: 530px;
            padding: 10px;
            border-top: 1px solid $tdx-gray;

            @include min {
              width: 490px;
            }

            @include small {
              width: 50% ;
            }

            @include little {
              width: 100% ;
              justify-content: space-between;
            }

            .name {
              font-weight: 400 !important;
            }

            p {
              width: 50%;
            }
          }
        }
      }
    }

    .additional-info {
      margin-top: 10px;

      .body-1 {
        font-weight: 400 !important;
      }
    }
  }
}
