
@import "../../variables";
@import "../../mixins";

.tdx-compare-page {
  padding: 24px 0 60px 0;

  h1 {
    margin-bottom: 10px;

    @include small {
      text-align: center;
    }
  }

  .compare-container {
    position: relative;
    padding: 0 30px;

    .table-container {
      position: relative;
      overflow: scroll hidden;
      scrollbar-width: none; // Firefox

      @include little{
        max-width: 768px;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      table {
        th,
        td {
          border: 0;
          padding: 0;
        }
      }

      .compare-table {
        table-layout: fixed;
        border-collapse: separate;

        @include small {
          table-layout: auto;
        }

        .sticky-col {
          position: sticky;
          left: 0;
          width: 400px;
          max-width: 400px;
          min-width: 400px;
          background-color: $tdx-white;
          z-index: 10;

          @include small {
            width: 40%;
            max-width: 400px;
            min-width: 100px;
          }
           & h6 {
             @include small {
               font-size: 14px;
             }
           }

          & ~ th {
            width: 550px;
            min-width: 550px;
            max-width: 550px;

            @include little {
              width: 320px;
              min-width: 320px;
              max-width: 320px;
            }

            &:nth-child(2) {
              &:last-child {
                width: 100%;
              }

              &:nth-last-child(2) {
                width: 50%;
              }
            }

            &:nth-child(3):last-child {
              width: 50%;
            }
          }

          &.header,
          &.footer {
            padding: 20px;

            .tdx-button {
              width: 70%;
            }
          }

          &.header {
            vertical-align: bottom;

            .MuiFormControlLabel-root {
              margin-bottom: 20px;
            }
          }

          &.footer {
            vertical-align: top;

            p {
              margin-top: 10px;
            }
          }

          .body-header {
            border-radius: 4px 0 0 4px;
          }
        }

        .item-card {
          position: relative;
          padding: 20px;
          vertical-align: top;

          .delete-button {
            position: absolute;
            top: 10px;
            right: 0;
            padding: 5px;
            background-color: $tdx-black-alpha-4;

            @include hover {
              background-color: $tdx-black-alpha-12;
            }
          }

          img {
            width: 170px;
            height: 170px;
            object-fit: contain;
          }

          h6 {
            margin: 10px 0;

            a {
              text-decoration: none;
              color: $tdx-black-alpha-87;

              @include hover {
                text-decoration: underline;
              }
            }
          }

          p {
            color: $tdx-black-alpha-60;
          }

          .item-card__content {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            padding-right: 30px;

            .item-card__main {
              max-width: 180px;
              width: 100%;

              @include little {
                max-width:100%;
              }
            }

            .item-card__offers {
              display:flex;
              flex-direction: column;
              flex: 1;
              gap: 5px;
              max-width: calc(100% - 200px);
              width: 100%;

              @include little {
                max-width:100%;
              }

              &>.tdx-button {
                height: 58px;
                max-height: 58px;
              }

              .tdx-button {
                &.counter {
                  position: relative;
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  padding: 6px;
                  height: 58px;
                  max-height: 58px;
                  flex: 1 1;
                  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

                  .counter {
                    margin: 0 10px;

                    .MuiOutlinedInput-root {
                      font-size: 14px;
                      line-height: 20px;
                    }
                  }

                  .clear-icon {
                    color: #d91f2a;
                  }
                  .description-icon {
                    color: #4caf50;
                  }
                  .icon-button {
                    padding: 4px;
                    gap: 5px;
                    flex-direction: column;

                    @include hover {
                      background: unset;
                    }

                    .icon {
                      font-size: 1rem;
                    }
                    .label {
                      color: #262424;

                      @include little {
                        display:none;
                      }
                    }
                  }
                }
              }

              .buttons-wrapper {
                display: flex;
                flex-direction: column;
                gap: 5px;

                &>.tdx-button {
                  height: 58px;
                  max-height: 58px;
                }
              }
            }
          }
        }

        th,
        td {
          .body-header {
            min-height: 30px;
            padding: 5px 10px;
            border-radius: 0 4px 4px 0;
            background: $tdx-black-alpha-4;
          }
        }

        tr td {
          border-top: 1px solid $tdx-gray;
        }

        tbody {
          tr[data-type="header"] + tr td {
            border-top: 0;
          }

          td {
            &:not(:last-child) {
              border-right: 1px solid $tdx-gray;
            }

            &[data-checked="true"] {
              background-color: #ffecc4;
            }

            .body-2 {
              padding: 10px 20px;
              font-weight: 400 !important;
              white-space: pre-wrap;
            }
          }
        }

        tfoot {
          .sticky-col {
            .item-container {
              justify-content: flex-start;

              .tdx-button {
                margin-bottom: 40px;
              }
            }
          }
        }
      }
    }
  }

  .scroller {
    position: sticky;
    bottom: 0;
    z-index: 10;

    .scroll {
      overflow-x: scroll;
      margin-left: 400px;
    }
  }
}
