@import "../../variables";
@import "../../mixins";

.tdx-balance-page {
  padding: 24px 0 60px 0;

  @include small {
    padding-bottom: 20px;
  }

  &.wrapper {
    @include small {
      width: 95%;
      margin: 0 auto;
    }
  }
  .title-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    @include small {
      gap: 20px;
    }

    .reconciliation {
      display: flex;
      align-items: center;

      .body-1 {
        font-weight: 400 !important;

        &.date {
          font-weight: 700 !important;
          margin-right: 16px;
        }
      }

      .MuiButton-root {
        min-width: 160px;
        height: 30px;
        font-size: 13px !important;
      }

    }

  }

  .status-block {
    display: flex;
    padding: 8px 10px;
    margin-bottom: 16px;
    background: $tdx-black-alpha-4;
    border-radius: 4px;

    .status-item {
      margin-right: 70px;

      .subtitle-2 {
        font-weight: 400 !important;
        color: $tdx-black-alpha-60;
      }

      h6 {
        color: $tdx-black-alpha-87;
      }
    }
  }

  .table-mobile {

    .table-group.error {
      color: $tdx-red;

      button {
        padding: 0 5px;
        vertical-align: baseline;

        svg {
          color: $tdx-red;
          width: 15px;
          height: 15px;
        }
      }
    }

    .mobile-card {
      .row {
        gap: 0;

        .mobile-card-first-row {
          flex-direction: column;
          margin-bottom: 0;
          gap: 5px;

          .mobile-card-checkbox-column {
            width: 100% !important;
            max-width: 100% !important;
            min-width: 100% !important;
            font-weight: bold;
            font-size: 18px;
            padding-left:15px;
          }

          .mobile-card-wrapper {
            padding-left: 15px;
            margin-bottom: 5px;

            .mobile-card-cell {
              &:first-child {
                font-size: 12px;
                color: $tdx-black-alpha-30;
              }

              & span {
                font-weight: bold;
              }
            }

            & .col-balans {
              color: $tdx-green-alpha-70;
            }
          }
        }

        .mobile-card-second-row {
          margin: 0 auto 10px auto;
          align-items: start;

          .cell {
            margin: 0;
            width: 100% !important;
            max-width: 100% !important;
            min-width:unset !important;

            &:first-child {
              font-size: 14px !important;
              font-weight: normal !important;
              padding-left: 15px;
            }

            &:last-child {
              padding: 5px 15px;
              margin: 5px 0 0;
              background-color: $tdx-light-gray;
              font-weight: normal !important;
              border-radius: $tdx-btn-border-radius;
              color: $tdx-green-alpha-70;

              & span {
                font-weight: bold !important;
              }
            }

            p {
              span {
                font-weight: bold !important;
              }
            }
          }
        }
      }
    }
  }
}
