@import "../variables";
@import "../mixins";

.tdx-modal-window {
  position: relative;

  &.disable-close {
    .close-button {
      pointer-events:none;
      opacity:0.4;
    }
    .MuiBackdrop-root {
      pointer-events:none;
    }
  }

  .MuiBackdrop-root {
    background-color: rgba(252, 252, 252, 0.8);
  }

  .tdx-modal-window-paper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 1170px;
    max-height: 80%;
    border-radius: 10px;
    overflow: hidden auto;
    outline: none !important;

    @include min {
      max-width: 1000px;
    }

    @include small {
      max-width: 97vw;
      width:100%;
    }

    @include little {
      max-height: 90%;
    }

    .close-button {
      position: absolute;
      right: 10px;
      top: 10px;
      padding: 4px;
      background-color: $tdx-black-alpha-4;
      z-index: 10;

      .MuiSvgIcon-root {
        font-size: 28px;
        color: rgba(0, 0, 0, 0.54);
      }

      &:hover {
        background-color: $tdx-black-alpha-12;

        .MuiSvgIcon-root {
          color: $tdx-black-alpha-75;
        }
      }
    }

    .title {
      text-align: center;
      padding: 20px 80px;

      @include little {
        padding: 10px 50px;
      }
    }
  }

  &.popup {
    .tdx-modal-window-paper {
      max-height: 95%;

      .popup-wrapper {

        &.tdx-popup {
          width: 100%;
          height: 100%;
          min-width: 320px;
          min-height: 100px;
          background: #fff;
          display: flex;
          align-items: center;
          justify-content: center;

          &>a {
            text-decoration: none;
          }

          .popup-content {
            .popup-text {
              margin: 1em;
            }

            .tdx-button {
              align-items: center;
              display: flex;
              margin: 1em auto;
              text-decoration: none;
              max-width: 200px;
              justify-content: center;
            }
          }
        }

        img.bg {
          object-fit: contain;
          width: 100%;
          height: 100%;
          position: relative;
          display:flex;
        }
      }
    }

    &.close-btn-white {
      .close-button {
        svg {
          color: #fff;
        }

        &:hover {
          svg {
            opacity: 0.7;
            color: #fff;
          }
        }
      }
    }

    &.popup-poll {
      .poll-header {
        background: #D9D9D9;
        padding: 20px 70px;
      }
      .poll-body {
        padding: 30px 0;

        .inputs-container {
          padding: 0 70px;
          gap:20px;
          align-items: start;

          .question {
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 100%;

            .question-title {
              font-weight:bold;
            }
            .answer {
              display: flex;
              gap: 10px;
              flex-wrap: wrap;

              textarea {
                overflow-y: hidden;
                resize: none;
              }

              .form-control {
                width: 100%;
                font-size: 1rem;
                padding: 10px;
                border-radius: 7px;
                border-color: #B9B9B9;
              }

              .input-group {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 2px;
                font-weight: bold;
                font-size: 0.9rem;

                label {
                  max-width: 130px;
                  text-align: center;
                }

                .form-radio,
                .form-checkbox {
                  height: 100%;
                  min-height: 17px;
                  max-height: 17px;
                  width: 100%;
                  min-width: 17px;
                  max-width: 17px;
                  accent-color: #35a123 !important;
                }
              }
            }
          }
        }
        .submit-container {
          background: none;

          .tdx-button {
            width: fit-content;
            margin: 0 auto;
          }
        }
      }
    }

    &.popup-poll-step {
      .poll-header {
        padding: 20px 3.5em 20px 1em;
        font-weight: 500;
      }
      .popup-text {
        text-align: center;
      }
    }

    &.poll-delivery-service-close {
      .tdx-modal-window-paper {
        width: 500px;

        .poll-body {
          .inputs-container {
            .question {
              .answer {
                flex-direction: column;

                .input-group {
                  flex-direction: row;
                  gap: 1em;

                  label {
                    max-width: 100%;
                    text-align: left;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .tdx-form {
    .container {
      width:100%;

      &:first-child {
        padding-top: 10px;
      }
    }
  }
}
