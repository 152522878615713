@import "../../mixins";
@import "../../variables";

.tdx-privacy-page {
  padding: 24px 0 60px 0;

  &.wrapper {
    @include small{
      width: 95%;
      margin: 0 auto;
      padding-bottom: 20px;
    }
  }

  .body {
    width: 100%;

    h1 {
      margin: 24px 0;

      @include small {
        text-align: center;
      }
    }

    p {
      font-size: 16px;
      line-height: 22px;
      font-weight: 500;
      color: $tdx-black-alpha-87;
      margin: 6px 0;

      @include min {
        font-size: 14px;
      }
    }

    img {
      display: block;
      width: 100%;
      object-fit: cover;
    }

    ul {
      margin: 0;
      padding: 0 0 0 1em;

      li {
        font-size: 15px;
        line-height: 18px;
        font-weight: 500;
        color: $tdx-black-alpha-87;

        &:not(:last-child) {
          margin-bottom: 24px;
        }

        &::marker {
          display: block;
        }
      }
    }
  }
}
