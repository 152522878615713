@import "../../variables";
@import "../../mixins";

.tdx-matching-page {
  padding: 24px 0 60px 0;

  &.wrapper {

    @include small {
      width: 95%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 20px;
    }
  }


  h1 {
    margin-bottom: 16px;
  }

  .group-button {
    margin-top:20px;
    display:flex;
    flex-wrap:wrap;
    gap:10px;

    .tdx-button {
      width:170px;
      max-width:170px;
      min-width: 170px;
    }
  }

}
