// Colors
$tdx-red: #d91f2a;
$tdx-red-alpha-70: rgba(217, 31, 42, 0.7);
$tdx-red-alpha-50: rgba(217, 31, 42, 0.5);
$tdx-red-alpha-30: rgba(217, 31, 42, 0.3);
$tdx-red-alpha-10: rgba(217, 31, 42, 0.1);
$tdx-red-alpha-8: rgba(217, 31, 42, 0.08);
$tdx-burgundy: #ae1922;
$tdx-green: #4caf50;
$tdx-green-dark: #238126;
$tdx-green-verydark: #44bf4ddb;
$tdx-green-alpha-10: rgba(17, 165, 67, 0.1);
$tdx-green-alpha-30: rgba(17, 165, 67, 0.3);
$tdx-green-alpha-70: rgba(17, 165, 67, 0.7);
$tdx-green-font: #68AD5C;
$tdx-green-font-alpha-20: rgb(45 255 54 / 28%);
$tdx-blue: #365fb9;
$tdx-blue-alpha-10: rgba(54, 95, 185, 0.1);
$tdx-white: #fcfcfc;
$tdx-gray: #e0e0e3;
$tdx-gray-light: #EEE;
$tdx-light-gray: #F5F5F5;
$tdx-gray-light-text: #9e9e9f;
$tdx-gray-light-text-alpha: #9f9e9e1f;
$tdx-black: #262424;
$tdx-black-alpha-87: rgba(0, 0, 0, 0.87);
$tdx-black-alpha-75: rgba(0, 0, 0, 0.75);
$tdx-black-alpha-60: rgba(0, 0, 0, 0.6);
$tdx-black-alpha-30: rgba(0, 0, 0, 0.3);
$tdx-black-alpha-20: rgba(0, 0, 0, 0.2);
$tdx-black-alpha-12: rgba(0, 0, 0, 0.12);
$tdx-black-alpha-4: rgba(0, 0, 0, 0.04);
$tdx-black-alpha-2: rgba(0, 0, 0, 0.02);
$tdx-yellow: rgba(255, 167, 5, 1);
$tdx-yellow-alpha-70: rgba(255, 167, 5, 0.7);
$tdx-yellow-light: rgb(168, 188, 5);
$tdx-yellow-light-alpha-10: rgba(207, 226, 45, 0.21);
$tdx-blue: #1976d2;
$tdx-blue-dark: #115eab;
$tdx-blue-alpha-10: rgba(41, 128, 212, 0.1);
$tdx-blue-alpha-30: rgba(41, 128, 212, 0.3);

// Buttons, Inputs
$tdx-btn-border-radius: 4px;
