@import "../variables";
@import "../mixins";

.tdx-arrow-link {
  display: flex;
  align-items: center;
  color: $tdx-black-alpha-87;
  text-decoration: none;

  &:hover {
    text-decoration: underline;

    svg {
      color: $tdx-red;
    }
  }

  svg {
    margin-left: 6px;
  }
}

.tdx-nav-link {
  color: $tdx-black-alpha-87;
  text-decoration-line: none;

  &:not(.active):hover {
    text-decoration-line: underline;

  }

  &.active {
    cursor: default;
    pointer-events: none;

    h5 {
      font-weight: 700;
    }
  }

  h5 {
    font-weight: 400;
  }
}

.tdx-profile-balance {
  display: flex;
  align-items: center;
  padding: 6px 10px 6px 7px;
  margin-left: 40px;
  border-radius: 4px;
  text-decoration: none;

  &:hover {
    background: $tdx-black-alpha-12;
  }

  h5 {
    margin-left: 8px;
    color: $tdx-black-alpha-87;
    line-height: 100%;
  }

  .icon-button {
    padding: 0 8px 8px;
    margin: 0 8px;

    .label {
      color: $tdx-black;
    }
  }
  .info-rounded-icon {
    color: $tdx-red-alpha-70 !important;
    width: 0.8em;
    height: 0.7em;
  }
}

.tdx-waiting-list {
  display: flex;
  align-items: center;
  padding: 6px 10px 6px 7px;
  margin-left: 40px;
  border-radius: 4px;
  text-decoration: none;

  h5 {
    margin-left: 8px;
    color: $tdx-black-alpha-87;
    line-height: 100%;
  }

  .icon-button {
    padding: 0 8px 8px;
    margin: 0 8px;
    gap: 5px;

    .label {
      color: $tdx-black;

      @include little {
        display:none;
      }
    }
  }
  .info-rounded-icon {
    color: $tdx-green-alpha-70 !important;
    width: 0.8em;
    height: 0.7em;
  }
}

.tdx-news-card {
  display: inline-flex;
  flex-direction: column;
  width: 370px;
  color: $tdx-black-alpha-87;
  text-decoration: none !important;
  overflow: hidden;

  @include min {
    width: 309px;
  }

  @include small {
    width: 48%;
  }
  &:not(:last-child) {
          @include small {
            margin-bottom: 15px;
          }
        }

  .image-container {
    position: relative;
    height: 160px;
    width: 100%;
    border-radius: 6px;
    overflow: hidden;

    @include little {
      height: auto;
      aspect-ratio: 1/1;
      border-radius:0;
    }

    .blackout {
      display: none;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: $tdx-black-alpha-30;
      z-index: 99;
    }

    img {
      width: 100%;
      max-width: 100%;
      height: 100%;
      object-fit: cover;
      transform: scale(1);
      transition: transform 0.1s ease;
    }
  }

  .info-container {
    margin-top: 15px;

    .body-2 {
      font-weight: 400 !important;
      line-height: 14px !important;

      &.description {
        line-height: 17px !important;
        color: $tdx-black-alpha-60;
      }
    }

    h6 {
      margin: 6px 0;
    }
  }

  &:hover {
    img {
      transform: scale(1.15);
    }

    h6 {
      text-decoration: underline;
    }
  }

  &:active {
    .blackout {
      display: block;
    }
  }
}

.tdx-stock-card {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 200px;
  padding: 13px 10px;
  color: #fff;
  text-decoration: none !important;
  overflow: hidden;

  .blackout {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: $tdx-black-alpha-30;
    border-radius: 6px;
    z-index: 1;
  }

  .image-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 6px;
    overflow: hidden;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: scale(1);
      transition: transform 0.1s ease;
    }
  }

  .body-2.period {
    font-weight: 400 !important;
  }

  &:hover {
    img {
      transform: scale(1.15);
    }

    h3 {
      text-decoration: underline;
    }
  }

  &:active {
    .blackout {
      display: block;
    }
  }
}

.catalog-categories {
  margin-bottom: 20px;

  .catalog-categories__list {
    padding: 0;
    list-style: none;
    margin: 0;

    button {
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;
      text-decoration: underline;
      text-align: left;
      display: inline-block;
      font-weight: 400 !important;
    }

    .catalog-categories__list-wrapper {
      display: grid;
      grid-template-rows: 0fr;
      overflow: hidden;
      transition: 300ms;
    }

    li {
      display: grid;

      &.has-dropdown {
        &>button {
          &:before {
            content: '+';
            margin-right: 0.5em;
            display: inline-block;
            width: 0.5em;
          }
        }
      }
    }

    &>li {
      overflow: hidden;
      margin-top: 5px;

      &.active {
        &>.catalog-categories__list-wrapper {
          grid-template-rows: 1fr;
        }

        &.has-dropdown {
          &>button {
            &:before {
              content: '-';
            }
          }
        }
      }

      &.current {
        &>button {
          font-weight: bold !important;
        }
      }
    }

    .catalog-categories__list {
      padding-left: 15px;
      overflow: hidden;
    }
  }
}