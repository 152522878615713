@import "../../variables";
@import "../../mixins";

.tdx-aside-filters-container {
  width: 60px;
  margin-right: 20px;
  border: 1px solid #e0e0e3;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: #fff;
  transition: width 300ms;
  position: relative;

  @include little {
    width: 100%;
    position:relative;
  }

  &.is-show {
    width: 320px;

    @include min {
      width: 240px;
    }

    @include small {
      width: 250px;
      margin-bottom: 20px
    }

    @include little {
      width: 100%;
      margin-bottom: 20px
    }

    .reset-filter {
      display:inline-flex;
    }

    .filters-wrapper {
      display: block;
    }

    .total-goods {
      display: flex;
    }

    .head-filter {
      .filter-icon {
        svg {
          transform: scaleX(-1);
        }
      }
    }
  }


  .is-active-filter-bar {

    @include little{
      display:block;
    }

    .head-filter {
      display: flex !important;
    }

    .filters-wrapper {
      display: block !important;
    }

    .total-goods {
      display: flex !important;
    }

  }
  .mobileFilterRender {
    display: none;
     width: 100%;

     & .is-active-arrow {
      transform: rotate(180deg);
    }
    & button {
      width: 100%;
      padding: 6px;
    }

    @include little {
      display:block;
    }
    .tdx-button__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      flex-direction: row;
      font-weight: bold;
      background-color: $tdx-light-gray;
      line-height: 2.3em;
      border-radius: 4px;
      padding: 0 10px;
    }

  }
  .filters-wrapper {
    padding: 12px 20px 24px;
    display:none;

    @include little {
      display: none !important;
    }

    .filter {

      &:not(:last-child) {
        margin-bottom: 18px;
      }

      .body-1 {
        font-weight: 700 !important;
        margin-bottom: 8px;

        @include min {
          font-size: 11px;
        }
      }

      .options-container {
        display: flex;
        flex-direction: column;

        .option {
          display: flex;
          align-items: center;

          .tdx-checkbox {
            margin-right: 6px;
          }

          .body-1 {
            font-weight: 400 !important;
            line-height: 18px;
          }
        }

        .tdx-button {
          margin-top: 10px;
        }
      }

      .tdx-button__content {
          display: inline-block;
      }
    }
  }

  .total-goods {
    display: none;
    flex-direction: column;
    align-items: center;
    background-color: $tdx-red-alpha-8;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    @include little {
      display: none !important;
    }

    &.body-1 {
      color: $tdx-red;
    }

    .total {
      display: flex;
      padding: 14px 20px;

      span {
        &:not(:last-child){
          margin-right: 0.3em;
        }
      }

    }
  }
}

.head-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include little {
    display: none !important;
  }

  .filter-icon {
    padding:0;

    @include hover {
      background:none;
    }

    svg {
      transition: 300ms;
    }
  }

  .reset-filter {
    display: none;

    .tdx-button__content {
      flex-direction: row;
      align-items: center;

      .clear-icon {
        color: #d91f2a;
      }

      svg {
        width: 0.8em;
        height: 0.8em;
      }
    }

    &.bg-transparent {
      @include hover {
        color: $tdx-red;
      }
    }
  }
}

.filter-items {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .filter-item {
    flex-direction:row;
    align-items: center;
    gap: 5px;
    border-radius: 9px;
    background: $tdx-gray-light;

    .label {
      font-weight: 600;
    }

    svg {
      width: 0.7em;
      height: 0.7em;
    }

    .clear-icon {
      color: $tdx-red;
    }
  }
}

*[role="tooltip"] {
    z-index: 10;
}